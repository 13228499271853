export class Type {
  constructor(
    public name: string,
    public acronym: string,
    public users: {
      adults: number,
      children: number
    },
    public rates: Map<number, number>
  ){}

  /* Method */
  static fromFirestore(data: any): Type {
    const { acronym, name, users} = data;
    //* Transform stringify key to number and map it *//
    let rates: Map<number, number> = new Map()
    for(const duration in data.rates){
      rates.set(Number(duration), data.rates[duration]);
    }

    return new Type(name, acronym, users, rates);
  }

  toFirestore(this: Type): any {
    return {
      name: this.name,
      acronym: this.acronym,
      users: {
        adults: this.users.adults,
        children: this.users.children,
      },
      rates: this.ratesToObject(this.rates)
    }
  }

  private ratesToObject(rates: Map<number, number>): any {
    //* key needs to be a string for Firestore *//
    return Array.from(rates.entries()).reduce(
      (main: any, [key, value]) => ({...main, [key.toString()]: value}),
      {}
    );
  }

  public deleteDuration(duration: number): void{
    this.rates.delete(duration);
  }
}
