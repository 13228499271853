import {Type} from "./type.model";
import { NewShop } from "./newShop.model";
import {set} from "@angular/fire/database";

export class Settings {
  constructor(
    public openingHours: {
      opening: {
        hour: number,
        minute: number
      },
      closing: {
        hour: number,
        minute: number
      }
    } = {
      opening: {
        hour: 9,
        minute: 0},
      closing: {
        hour: 19,
        minute: 0
      }
    },
    public languages: {
      actives: string[],
      displayTabs: string[]
    } = {actives: ['fr-FR'], displayTabs: ['fr-FR']},
    public types: Type[] = [],
    public durations: number[] = [],
    public beepers: {
      useBeepers: boolean,
      isMandatory: boolean,
      beeperNumFrom: number,
      beeperNumTo: number,
    } = {useBeepers: false, isMandatory: false, beeperNumFrom: 0, beeperNumTo: 0},
    public delayTillWaiting: number = 25,
    public delayMinimalForBoarding: number = 0,
    public debugMaterials: boolean = false,
    public debugRents: boolean = false,
    public iconsBucket: string | null = null,
    public backgroundImg: string | null = null
  ){}

  /* Firestore Method */
  static fromFirestore(data: any): Settings {
    let settings: Settings = new Settings();
    if(data.openingHours){
      settings.setOpeningTime(data.openingHours.opening.hour, data.openingHours.opening.minute);
      settings.setClosingTime(data.openingHours.closing.hour, data.openingHours.closing.minute);
    }

    if(data.languages){
      settings.languages = data.languages;
    }

    settings.types = [];
    if(data.types){
      data.types.forEach((type: any) => {
        settings.types.push(Type.fromFirestore(type))
      })
    }

    settings.durations = [];
    if(data.durations){
      settings.durations = data.durations;
    }

    if(data.beepers){
      settings.beepers = data.beepers;
    }

    if(data.delayTillWainting){
      settings.delayTillWaiting = data.delayTillWainting;
    }

    if(data.delayMinimalForBoarding){
      settings.delayMinimalForBoarding = data.delayMinimalForBoarding;
    }

    if(data.debugMaterials){
      settings.debugMaterials = data.debugMaterials;
    }

    if(data.debugRents){
      settings.debugRents = data.debugRents;
    }

    settings.iconsBucket = data.iconsBucket;
    settings.backgroundImg = data.backgroundImg;
    return settings;
  }

  static newShop(newShop: NewShop): Settings {
    let settings: Settings = new Settings();
    /* this set icons bucket in firebase storage */
    if(newShop.icon.name == 'custom'){
      settings.iconsBucket = newShop.shopId
    }

    if(newShop.icon.name != 'custom'){
      settings.iconsBucket = 'default/'+newShop.icon.name
    }

    /* set background image in firestore */
    if(newShop.background.name == 'custom'){
      settings.backgroundImg = newShop.shopId+'/'+newShop.shopId+'.webp';
    }
    if(newShop.background.name != 'custom'){
      settings.backgroundImg = 'default/'+newShop.background.name+'.webp';
    }

    return settings;
  }

  public toFirestore(this: Settings): any{
    return {
      openingHours: Object.assign({}, this.openingHours),
      languages: this.languages,
      types: this.typesToFirestore(this.types),
      durations: this.durations,
      beepers: this.beepers,
      delayTillWaiting: this.delayTillWaiting,
      delayMinimalForBoarding: this.delayMinimalForBoarding,
      debugMaterials: this.debugMaterials,
      debugRents: this.debugRents,
      iconsBucket: this.iconsBucket,
      backgroundImg: this.backgroundImg
    }
  }

  /* Method on OpeningHours */
  public setOpeningTime(hour: number, minute: number): void {
    this.openingHours.opening.hour = hour;
    this.openingHours.opening.minute = minute;
  }

  public getOpeningTime(): Date {
    /* return Today openingTime OR Tomorrow openingTime */
    let now: Date = new Date();
    let openingTime: Date = new Date();
    let todayClosingTime: Date = new Date();
    todayClosingTime.setHours(this.openingHours.closing.hour, this.openingHours.closing.minute, 0, 0);

    openingTime.setHours(this.openingHours.opening.hour, this.openingHours.opening.minute, 0, 0);
    if(todayClosingTime < now){
      // shop already closed for today, return tomorrow opening time //
      openingTime = new Date(openingTime.getTime() + 24 * 60 * 60 * 1000);
    }
    return openingTime;
  }

  public setClosingTime(hour: number, minute: number): void {
    this.openingHours.closing.hour = hour;
    this.openingHours.closing.minute = minute;
  }

  public getClosingTime(): Date {
    /* return Today closingTime OR Tomorrow closingTime */
    let now : Date = new Date();
    let closingTime: Date = new Date();
    closingTime.setHours(this.openingHours.closing.hour, this.openingHours.closing.minute, 0, 0);

    if(closingTime < now){
      // shop already closed for today, get tomorrow closing time //
      closingTime = new Date(closingTime.getTime() + 24 * 60 * 60 * 1000);
    }

    return closingTime;
  }

  /* Method on types */
  public getTypes(): Type[] {
    return this.types;
  }

  public get getBeepersList(): string[] {
    let list: string[] = []
    for(let i: number = this.beepers.beeperNumFrom; i <= this.beepers.beeperNumTo; i++){
      list.push(i.toString());
    }
    return list;
  }

  public getTypeByAcronym(acronym: string): Type | null {
    let response: Type[] = this.types.filter((type: Type) => {
      return type.acronym == acronym
    });

    if(response.length == 0){
      return null;
    }

    if(response.length == 1){
      return response[0]
    }

    console.warn('error in find type by id, more than one type with the same acronym');
    window.alert('error in find type by id, more than one type with the same acronym');
    return null;
  }

  public addType(newType: Type): boolean {
    let exist: boolean = false;
    /* check if type with same acronym exist */
    this.types.forEach((type: Type): void => {
      if(type.acronym == newType.acronym){
        console.warn('This durations already exist');
        exist = true;
      }
    });

    if(exist){
      return false;
    }

    this.types.push(newType);
    //this.types.sort((a, b) => a.acronym.localeCompare(b.acronym));
    // Drag and drop active //
    return true;
  }

  public deleteType(acronym: string): boolean {
    let index: number | undefined = undefined;
    this.types.forEach((type: Type, i: number): void => {
      if(type.acronym == acronym){
        index = i;
      }
    });

    if(index){
      this.types.splice(index, 1);
      return true;
    } else {
      console.warn('this type do not exist: '+acronym);
      return false
    }
  }

  public sortTypeOnAcronymArray(acronymArray: string[]): void {
    let newOrder: Type[] = [];
    acronymArray.forEach((acronym: string): void => {
      let type: Type | null = this.getTypeByAcronym(acronym)
      if(type != null){
        newOrder.push(type);
      }
    });
    this.types = newOrder;
  }

  private typesToFirestore(types: Type[]): any[] {
    let classFree: any[] = [];
    types.forEach((type: Type): void => {
      classFree.push(type.toFirestore());
    });
    return classFree;
  }

  public updateRates(acronym: string, duration: number, price: number): void {
    this.types.forEach((type: Type): void => {
      if(type.acronym == acronym){
        type.rates.set(duration, price);
      }
    });
  }


  /* Method on durations */
  public getDurations(): number[] {
    return this.durations;
  }

  public addDurations(duration: number): boolean {
    if(this.durations.includes(duration)){
      console.warn('This durations already exist');
      return false;
    }
    this.durations.push(duration);
    this.durations = this.durations.sort((n1: number,n2: number) => n1 - n2);

    /* this duration is added in every types */
    this.types.forEach((type: Type): void => {
      type.rates.set(duration, 0);
      // sort map asc for clean data //
      type.rates = new Map([...type.rates].sort(([key1, value1], [key2, value2]) => {return  key1 - key2}))
    });
    return true;
  }

  public deleteDuration(duration: number): boolean {
    if(!this.durations.includes(duration)){
      console.warn('this durations isn\'t a part of durations array: '+duration);
      return false;
    }

    const index: number = this.durations.indexOf(duration);
    if (index > -1) { // only splice array when item is found
      this.durations.splice(index, 1); // 2nd parameter means remove one item only
    }

    this.types.forEach((type: Type) => {
      type.deleteDuration(duration);
    });
    return true;
  }
}
