import { Injectable } from '@angular/core';
import {
  Firestore,
  WriteBatch,
  collection,
  doc,
  where,
  addDoc,
  deleteDoc, deleteField, setDoc, updateDoc,
  onSnapshot,
  getFirestore,
  writeBatch,
  enableIndexedDbPersistence,
  runTransaction,
  query,
  DocumentData, DocumentReference, DocumentSnapshot, Unsubscribe, FirestoreError, getDoc
} from "@angular/fire/firestore";
import {BehaviorSubject} from "rxjs";
import {Shop} from "@models/shop.model"
import {AuthService} from "./auth.service";
import {Fleet} from "@models/fleet.model";
import {Material} from "@models/material.model";
import {TodayRents} from "@models/todayRents.model";
import {Rent} from "@models/rent.model";
import {StatisticsDocument} from "@models/statistics.model";


interface SelectedDate{
  year: string | undefined, month: string | undefined, day: string | undefined
}

@Injectable({
  providedIn: 'root'
})
export class FsService {
  private readonly db: Firestore = getFirestore();
  private batch: WriteBatch;

  constructor(private auth: AuthService) {
    /** auth is needed maybe somewhere else but still **/
    /* offline mode */
    enableIndexedDbPersistence(this.db)
      .catch((err) => {
        if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a time.
          window.alert('multiple tab open')

        } else if (err.code == 'unimplemented') {
          // The current browser does not support all
          // features required to enable persistence
          // ...
          window.alert('offline mode impossible sur ce navigateur')
        }
      });
    this.batch = writeBatch(this.db);
    // Subsequent queries will use persistence, if it was enabled successfully
  }

  private shopListener: Unsubscribe | undefined;
  private fleetListener: Unsubscribe | undefined;
  private rentsListener: Unsubscribe | undefined;


  /* WARN change for Material Logic */
  public fleet2$: BehaviorSubject<Fleet> = new BehaviorSubject<Fleet>(new Fleet());
  public todayRents$: BehaviorSubject<TodayRents> = new BehaviorSubject<TodayRents>(new TodayRents());
  public shop$: BehaviorSubject<Shop | null> = new BehaviorSubject<Shop | null>(null);
  private shopId: string = '';
  public isInit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public statistics$: BehaviorSubject<StatisticsDocument | null> = new BehaviorSubject<StatisticsDocument | null>(null);

  public async getShop(shopId: string | null): Promise<void> {
    console.log(`getShop(${shopId})`);
    /** mount requested shop data in shop$: Shop | null, dismount shop$ if called with null **/
    try{
      if(this.shop$.value == shopId){
        /** we avoid trigger if unnecessary **/
        return
      }

      if(this.shopListener){
        /** if shop listener active => stop listening **/
        console.log('stop listening last registration')
        this.shopListener();
      }

      /** if shopId == null dismount shop$ **/
      if(shopId == null || !shopId){
          this.shop$.next(null);
        return
      }

      let docRef = doc(this.db, 'shops', shopId);

      this.shopListener = onSnapshot(docRef, { includeMetadataChanges: true }, (document) =>{
        if (!document.exists()) {
          this.shop$.next(null);
          console.warn(`fsService.getShop(${shopId}): This shop doc do not exist`);
        }  else {
          let shop: Shop = Shop.fromFirestore(document.id, document.data());
          this.shopId = document.id;
          this.shop$.next(shop);
        }

        if (!this.isInit$.value) {
          this.isInit$.next(true);
        }
      })
    } catch(e: any){
      console.warn(`fsService.getShop(${shopId})`);
      return
    }
  }

  public async getFleet(shopId: string | null): Promise<void>{
    /** mount requested shopId fleet$: Fleet(Material[]) for shopId, dismount fleet$ if called with null */
    try {
      /** if active listener =>  stop listening **/
      if(this.fleetListener){
        this.fleetListener();
      }

      if(shopId == null || !shopId){
        /** if no shop => dismount **/
        this.fleet2$.next(new Fleet([]));
        return
      }

      const q = query(collection(this.db, 'shops', shopId, 'fleet'));
      let fleet: Fleet = new Fleet([]);

      this.fleetListener = onSnapshot(q, (querySnapshot): void => {
        querySnapshot.docChanges().forEach((change) => {
          let doc = change.doc
          let material: Material = Material.fromFirestore(doc.id, doc.data())

          if(change.type === 'added'){
            fleet.add(material);
          }

          if(change.type === 'modified'){
            fleet.update(material);
          }

          if(change.type === 'removed'){
            fleet.remove(material);
          }
        });
        this.fleet2$.next(fleet);
      });
    } catch(e){
      console.warn('ERROR: fs.service => getFleet(`{$shopId}`)');
      console.error(e)
      return;
    }
  }


  public async getRents(shopId: string | null): Promise<void>{
    /** mount requested shopId rents$: Rent[], dismount if called with null **/
    try {
      if(this.rentsListener){
        this.rentsListener()
      }

      if(shopId == null || !shopId){
        this.todayRents$.next(new TodayRents());
        return
      }

      const q = query(collection(this.db, 'shops', shopId, 'rents'),
        where("status", "!=", "ended"));
      let todayRents: TodayRents = new TodayRents();


      this.rentsListener = onSnapshot(q, (querySnapshot): void => {
        querySnapshot.docChanges().forEach((change): void => {
          let doc = change.doc;
          let rent: Rent = Rent.fromFirestore(doc.id, doc.data());
          if(change.type === 'added'){
            todayRents.add(rent);
          }

          if(change.type === 'modified'){
            todayRents.update(rent);
          }

          if(change.type === 'removed'){
            todayRents.remove(rent);
          }
        });
        this.todayRents$.next(todayRents);
      });
    } catch(e) {
      console.warn('ERROR: fs.service => getRents(`{$shopId}`)');
      console.error(e)
      return
    }
  }

  public getStatistics(selectedDate: SelectedDate): Unsubscribe{
    try {
      let docRef: DocumentReference<DocumentData> = doc(this.db, "shops", this.shopId, "statistics", "global");

      if (selectedDate.year && !selectedDate.month && !selectedDate.day) {
        docRef = doc(this.db, "shops", this.shopId, "statistics", "global", "year", selectedDate.year);
      }
      if (selectedDate.year && selectedDate.month && !selectedDate.day) {
        docRef = doc(this.db, "shops", this.shopId, "statistics", "global", "year", selectedDate.year, "month", selectedDate.month);
      }
      if (selectedDate.year && selectedDate.month && selectedDate.day) {
        docRef = doc(this.db, "shops", this.shopId, "statistics", "global", "year", selectedDate.year, "month", selectedDate.month, "day", selectedDate.day);
      }
     return onSnapshot(docRef, (doc) => {
       if(doc.exists()){
         console.log(selectedDate);
         const statisticsDocument: StatisticsDocument = StatisticsDocument.fromFirestore(doc.data())
         console.log(statisticsDocument);
         this.statistics$.next(statisticsDocument);
       } else {
         console.log(selectedDate);
         console.log('unworked day');
         this.statistics$.next(null)
       }

     });
    } catch (err: any) {
      console.warn(err);
      throw new Error(err);
    }
  }

  public statisticsRentsData$: BehaviorSubject<Rent[]> = new BehaviorSubject<Rent[]>([])
  public getStatisticsData(pathSegments: string[]): Unsubscribe{
    pathSegments.unshift(this.shopId);
    try {
      let documentRef = doc(this.db, 'shops', ...pathSegments);
      return onSnapshot(documentRef,
        async (doc: DocumentSnapshot<DocumentData>): Promise<void> => {
        if(doc.exists()){
          const rents: Rent[] = []
          const firestoreRents: DocumentData = doc.data();
          Object.keys(firestoreRents).forEach((id: string) => {
            rents.push(Rent.fromFirestore(id, firestoreRents[id]))
          });
          this.statisticsRentsData$.next(rents);
        }
      },
        (err:  FirestoreError) => {throw new Error(err.message)});
    } catch(err: any){
      throw new Error(err);
    }
  }

  /* FIREBASE GET DOCUMENT */
  public async getFirebaseDoc(pathSegments: string[], docId: string): Promise<DocumentSnapshot<DocumentData> | undefined> {
    try {
      if (pathSegments.length % 2 == 0) {
        throw new Error('path segment should be odd')
      }
      pathSegments.push(docId)
      let path = pathSegments.join('/');
      let docRef = doc(this.db, path)
      return await getDoc(docRef);
    } catch (e) {
      console.error(e);
      return
    }
  }

  /* ADD & UPDATE */

  public async update(pathSegments: string[], data: any){
    pathSegments.unshift(this.shopId);
    try {
      if (pathSegments.length % 2 == 0) { // de d'id de document indéfini => addDoc
        data = this.toFirestore(data, 'add');
        let collectionRef = collection(this.db, 'shops', ...pathSegments);
        await addDoc(collectionRef, data);
      } else { // Id du document défini : update
        data = this.toFirestore(data, 'update');
        let docRef = doc(this.db, 'shops',...pathSegments);
        await updateDoc(docRef, data);
      }
    } catch(e) {
     console.warn(e);
    }
  }

  public async set2(pathSegments:string[], data: any): Promise<void>{
    /* set doc, with ID ['collection', id], if pathSegments[0] == shops, can create new shop */
    let docRef;
    if(pathSegments[0] == 'shops'){
     if(typeof pathSegments[1] !== 'string' || pathSegments[1] == '' || pathSegments.length != 2 ){
       console.warn('error in fsService.set');
       console.warn('invalid shops id || pathSegment.length != 2');
       return;
     }
     console.log('NEW SHOP: '+pathSegments[1]);
      docRef = doc(this.db, 'shops', pathSegments[1])
    } else {
      pathSegments.unshift(this.shopId);
      docRef = doc(this.db, 'shops', ...pathSegments)
    }
    await setDoc(docRef, data);
  }

  public async create2(pathSegments: string[], data: any): Promise<string>{
    /* create auto id docs, nested in shops/shopId/['collection', docId, 'collection' ...] */
    try{
      pathSegments.unshift(this.shopId);
      let colRef = collection(this.db, 'shops', ...pathSegments);
      delete data.id;
      const newDocRef = await addDoc(colRef, data);
      return newDocRef.id;
    } catch(err: any){
      console.warn('error in fs.create2');
      console.warn(err);
      throw new Error(err)
    }
  }

  public async docSnap(pathSegments: string[]): Promise<{sub: Unsubscribe | null, data$: BehaviorSubject<DocumentSnapshot<DocumentData> | null>}> {
    /* get ['collection', 'id', 'collection', 'id'...] WARNING: NO NEED FOR ['shops', shopId] automatically added */
    let response: {sub: Unsubscribe | null, data$: BehaviorSubject<DocumentSnapshot<DocumentData> | null>} = {
      sub: null,
      data$: new BehaviorSubject<any>(null)
    }

    pathSegments.unshift(this.shopId);
    let docRef = doc(this.db, 'shops', ...pathSegments);
    try {
      response.sub = onSnapshot(docRef, (doc: DocumentSnapshot<DocumentData>): void => {
        if(doc.exists()){
          response.data$.next(doc);
        } else {
          response.data$.next(null);
        }

      });
      return response;
    } catch(e){
      console.warn('error in fs.docSnap');
      console.warn(e)
      return response;
    }
  }

  public async update2(pathSegments: string[], data: any): Promise<void> {
    pathSegments.unshift(this.shopId);
    data.id? pathSegments.push(data.id): console.log('If not to update Shops.shop => error no id was passed');
    delete data.id;
    let docRef = doc(this.db, 'shops', ...pathSegments);
    try {
      await updateDoc(docRef, data)
    } catch (e: any) {
      console.warn('error in fs.update');
      console.warn(e);
      throw new Error(e);
    }
  }

  public async delete2(pathSegments: string[], docId?: string): Promise<void> {
    pathSegments.unshift(this.shopId);
    docId? pathSegments.push(docId): null;
    let docRef = doc(this.db, 'shops', ...pathSegments);
    try {
      await deleteDoc(docRef);
    } catch (e: any) {
      console.warn('error in fs.delete');
      console.warn(e);
      throw new Error(e)
    }
  }


  // Batch Write //
  public addToBatch(pathSegments: string[], data: any): string {

    // put document in batch in waiting to be sent to firestore, return a document reference;
    // create or update passed document based on the existence of: id in data: any //
    if(data.id){ // Id exist => update document
      pathSegments.unshift(this.shopId);
      pathSegments.push(data.id);
      /** if role != reception or admin **/
      delete data.id;
      const docRef: DocumentReference<DocumentData> = doc(this.db, 'shops', ...pathSegments);
      this.batch.update(docRef, data);
      return docRef.id
    } else { // id do not exist => create document;
      pathSegments.unshift(this.shopId);
      const colRef: DocumentReference<DocumentData> = doc(collection(this.db, 'shops', ...pathSegments));
      this.batch.set(colRef, data);
      return colRef.id;
    }
  }

  public async commitBatch(): Promise<void> {
    try {
      this.batch.commit();
      this.batch = writeBatch(this.db);
    } catch (e) {
      console.warn('fsService.commitBatch() error: ');
      console.warn(e);
    }
  }

  private transactionsMap: Map<string, {pathSegments: string[], data: any}> = new Map();
  private addToTransactions(pathSegments: string[], data: any){
    /** transaction are called for every role except reception **/
    /** we are going to create a mapOf document in the single transaction **/
    this.transactionsMap = new Map([]);
    if(!data.id){
      console.warn('error in addToTransactions: data do not have id, it is a doc creation, and this isn\'t supported yet');
      console.log(pathSegments);
      console.log(data);
      return
    }

    /** we make a unique id for that document based on it pathSegment and it id **/
    const docId: string = pathSegments.join('') + data.id;
    this.transactionsMap.set(docId, {pathSegments: pathSegments, data: data});
  }

  private async commitTransactions() {
    try {
      const docRefMap: Map<DocumentReference<DocumentData>, any> = new Map([]);
      await runTransaction(this.db, async (transaction) => {

        for (const [key, pending] of this.transactionsMap) {
          const docRef: DocumentReference<DocumentData> = doc(this.db, 'shops', ...pending.pathSegments);
          const docInDB: DocumentSnapshot<DocumentData> = await transaction.get(docRef);
          /** if dbDocs.has(key) => this transaction has already bee, called => throw error; **/
          console.log(docInDB.data());
          if(docRefMap.has(docRef)){
            throw {state: 'error', message: 'doc changed', docId: pending.data.id, updatedDoc: docInDB.data()};
          }

          if (!docInDB.exists()) {
            throw {state: 'error', message: 'doc do not exist', docId: pending.data.id, updatedDoc: null};
          }
          docRefMap.set(docRef, pending.data);
        }

        console.log(docRefMap);
        for (const [docRef, data] of docRefMap) {
          console.log(docRef);
          console.log(data)
          delete data.id;
          transaction.update(docRef, data);
        }
      });
      this.transactionsMap.clear();
      console.log("Transaction successfully committed!");
      /** transaction success **/
      /** TODO success object => proceed in calling code **/
    } catch(e) {
      /** custom error format: {state: 'error', message: string, target: string[]} **/
      /** TODO error object => rebuild data in code, update data in commitTransactionMap, call commit again; **/
      console.warn('!! transaction error !!');
      console.log(e);
    }
  }

  // Delete
  public async deleteSpecificField(pathSegments: string[], field: string): Promise<void>{
    /** delete specific field in the document, pathSegments need to target a document **/
    pathSegments.unshift(this.shopId);
    let docRef = doc(this.db, 'shops', ...pathSegments);
    try {
      const updateObject = { [field]: deleteField() };
      await updateDoc(docRef, updateObject);
    } catch (e: any){
      console.warn('error in fs.deleteSpecificField');
      console.warn(e);
      throw new Error(e);
    }
  }

  private toFirestore(dirty: any, mode: 'add' | 'update' = 'add'): any{

    if(typeof dirty == 'object'){
      let classFree = Object.assign({}, dirty);
      Object.keys(classFree).forEach((key: string): void => {
        if(typeof key == 'object'){
          classFree[key] = this.toFirestore(classFree[key], mode);
        }
        if(classFree[key] == undefined || classFree[key] == null){
          if(mode == 'add'){
            delete classFree[key];
          }
          if(mode == 'update'){
            classFree[key] = deleteField();
          }
        }
      })
      return classFree;
    }
  }
}
