import {Settings} from "./settings.model";
import {Type} from "./type.model";
import {NewShop} from "./newShop.model";
export class Shop {
  constructor(
    public id: string,
    public name: string = 'Connection en cours',
    public settings: Settings
  ) {}

  static fromFirestore(id: string, data: any): Shop{
    return new Shop(
      id,
      data.name,
      Settings.fromFirestore(data.settings)
    )
  }

  static create(newShop: NewShop): Shop {
    return new Shop(
      newShop.shopId,
      newShop.shopName,
      Settings.newShop(newShop)
    )
  }

  public toFirestore(this: Shop): any{
    return {
      name: this.name,
      settings: this.settings.toFirestore()
    }
  }

  get getName(): string {
    return this.name;
  }

  get isSet(): boolean {
    /** return true if a shop is mounted, false if not: it is the case during initialisation */
    return this.id != '';
  }

  public getOpeningTime(): Date{
    return this.settings.getOpeningTime();
  }

  public getClosingTime(): Date {
    return this.settings.getClosingTime();
  }

  public getDurations(): number []{
    return this.settings.durations
  }

  public getTypes(): Type[]{
    return this.settings.getTypes();
  }

  public getTypeByAcronym(acronym: string): Type | null {
    return this.settings.getTypeByAcronym(acronym);
  }

  public getPriceByAcronymAndDuration(acronym: string, duration: number ): number {
    let price = this.settings.getTypeByAcronym(acronym)?.rates.get(duration);
    if(typeof price == "number"){
      return price
    } else {
      console.warn('error in getPriceByAcronymAndDuration');
      return 0;
    }
  }

  public getAdultsByType(acronym: string): number {
    let adults: number | undefined = this.settings.getTypeByAcronym(acronym)?.users.adults
    if(typeof adults == "number"){
      return adults
    } else {
      console.warn('error in Shop.getAdultsByType');
      return 0
    }
  }

  public getChildrenByType(acronym: string): number {
    let children: number | undefined = this.settings.getTypeByAcronym(acronym)?.users.children
    if(typeof children == "number"){
      return children
    } else {
      console.warn('error in Shop.getChildrenByType');
      return 0
    }
  }

  public getTypesAcronym(): string[]{
    let acronyms: string[] = [];
    this.getTypes().forEach((type: Type) => {
      acronyms.push(type.acronym);
    });
    return acronyms;
  }
}

