import {Rent} from "./rent.model";

export class Material {
  constructor(
    public id: string,

    public types: string[],
    public status: 'ready' | 'boarding'  | 'sailing' | 'landing' | 'unavailable',
    private available: Date,
    private availableAsAtl: Date,
    public enable: boolean,
    public stored: boolean,
    public commissioning: Date, // First commissioning
    public usage: {
      today: number,
      total: number
    } = { today: 0, total: 0},
    public overbookingDuration: {
      today: number,
      total: number
    } = { today: 0, total: 0},
    public todayRents: {
      rentId: string,
      predictedStart?: Date,
      predictedEnd?: Date,
      rentalStart?: Date,
      rentalEnd?: Date,
      realEnd?: Date,
      start?: string,
      duration?: string,
      backgroundColor?: string,
      scheduleToDisplay?: string}[] = [],

    private lastFresh?: number, // used as a "checksum"
  ){}
  /* Method */
  static fromFirestore(id: string, data: any): Material {
    return new Material(
      id,
      data.types,
      data.status,
      data.available.toDate(),
      data.availableAsAlt ? data.availableAsAlt.toDate(): data.available.toDate(),
      data.enable,
      data.stored,
      data.commissioning.toDate(),
      data.usage,
      data.overbookingDuration,
      this.todayRentFromFirestore(data.todayRents),
      this.setLastFresh(),
    )
  }

  toFirestore(this: Material): any {
    let classFree: any = {
      id: this.id,
      types: this.types,
      status: this.status,
      available: this.available,
      availableAsAlt: this.availableAsAtl,
      enable: this.enable,
      stored: this.stored,
      commissioning: this.commissioning,
      usage: this.usage,
      overbookingDuration: this.overbookingDuration,
      todayRents: [],
    };

    this.todayRents.forEach((rent: Material["todayRents"][0], index: number): void => {
      classFree.todayRents[index] = {
        rentId: rent.rentId,
        predictedStart: rent.predictedStart,
        predictedEnd: rent.predictedEnd,
        rentalStart: rent.rentalStart,
        rentalEnd: rent.rentalEnd,
        realEnd: rent.realEnd
      }

      classFree.todayRents[index].predictedStart? null: delete classFree.todayRents[index].predictedStart ;
      classFree.todayRents[index].predictedEnd? null: delete classFree.todayRents[index].predictedEnd;
      classFree.todayRents[index].rentalStart? null: delete classFree.todayRents[index].rentalStart;
      classFree.todayRents[index].rentalEnd? null: delete classFree.todayRents[index].rentalEnd;
      classFree.todayRents[index].realEnd? null: delete classFree.todayRents[index].realEnd;

    })
    return classFree;
  }

  private static todayRentFromFirestore(todayRents: {rentId: string, customerName: string, predictedStart: any, predictedEnd: any, rentalStart: any, rentalEnd: any, realEnd: any}[]): any[] {
    let rent: any[] = []
    if(!todayRents){return rent}
    todayRents.forEach((r) => {
      rent.push({
        rentId: r.rentId,
        predictedStart: r.predictedStart? r.predictedStart.toDate(): undefined,
        predictedEnd: r.predictedEnd? r.predictedEnd.toDate(): undefined,
        rentalStart: r.rentalStart? r.rentalStart.toDate(): undefined,
        rentalEnd: r.rentalEnd? r.rentalEnd.toDate(): undefined,
        realEnd: r.realEnd? r.realEnd.toDate(): undefined
      })
    });
    return rent;
  }

  public copy(): Material{
    let copy: Material = Material.newMaterial('', []);
    Object.assign(copy, this);
    return copy;
  }

  get getId(): string {
    /* get material id */
    return this.id
  }

  get getStatus(): 'ready' | 'boarding'  | 'sailing' | 'landing' | 'unavailable' {
    /* get material status */
    return this.status;
  }

  get getTypes(): string[]{
    return this.types;
  }

  get getEnable(): boolean {
    return this.enable;
  }

  get getStored(): boolean {
    return this.stored;
  }

  public setStored(stored: boolean): void {
    this.stored = stored;
  }

  get getLastFresh(): number {
    return this.lastFresh? this.lastFresh: 0;
  }

  public isValidAlt(rentalStart: Date | null): boolean {
    /* return true if availableAsAlt <= rentalStart */
    if(rentalStart == null){
      console.warn('error in materialModel.isValidAlt: rentalStart should\'nt be null');
      return false;
    }
    return this.availableAsAtl <= rentalStart;
  }

public setAvailableAsAtl(rentalEnd: Date): void {
    /** this method is to call on a material who has been used as alternative to overbooking,
     *  it prevents material to be alternative multiple time at the same moment
     *  rentalEnd is: the material who use this material as alternative: it is the previous rental rentalEnd*/
    this.availableAsAtl = rentalEnd;
}

  public static setLastFresh(): number {
    return new Date().getTime();
  }

  public setStatus(status: 'ready' | 'boarding'  | 'sailing' | 'landing' | 'unavailable'): void {
    /* set material status, sailing status: un-store the material */
    this.status = status;
    if(status == 'sailing'){
      this.stored = false;
    }
  }

  public setEnable(enable: boolean): void {
    this.enable = enable;
  }

  public addRent(rent: Rent): void {
    // add new rent to this material => id is unknown ? maybe update after creation or during the batch if possible//
    this.todayRents.push({
      rentId: rent.id != null? rent.id: '',
      predictedStart: rent.getPredictedStart != null ? rent.getPredictedStart: undefined,
      predictedEnd: rent.getPredictedEnd != null ? rent.getPredictedEnd: undefined
    });
  }

  public updateRent(rent: Rent, realEnd: Date | undefined = undefined): void {
    /* Update nested todayRents with the value form rent, to close a rent remember to pass the realEnd (why ? should be taken form rent too) */
    const todayRentItem ={
      rentId: rent.id != null? rent.id: '',
      predictedStart: rent.getPredictedStart != null ? rent.getPredictedStart: undefined,
      predictedEnd: rent.getPredictedEnd != null ? rent.getPredictedEnd: undefined,
      rentalStart: rent.getRentalStart != null ? rent.getRentalStart: undefined,
      rentalEnd: rent.getRentalEnd != null ? rent.getRentalEnd: undefined,
      realEnd: realEnd
    }

    let index: number = this.todayRents.findIndex((elem): boolean => {return elem.rentId == rent.id});
    if(index == -1){
      console.warn('Error in material.model.updateRent: todayRent with id :'+rent.id+' is undefined');
      console.log(this);
      return
    }
      this.todayRents[index] = todayRentItem;
  }

  public deleteRent(rentId: string): void{
    // delete rent form todayRents //
    let index: number = this.todayRents.findIndex((elem): boolean => {return elem.rentId == rentId});
    if(index == -1){
      console.warn('Error in material.model.deleteRent: todayRent with id: '+rentId+' is undefined');
      console.log(this);
      return
    }
    this.todayRents.splice(index, 1);
    const lastRent = this.todayRents.at(-1);
    if(lastRent){
      if(lastRent.realEnd){
        this.available = lastRent.realEnd.roundNextFive();
        return
      }
      if(lastRent.rentalEnd){
        this.available = lastRent.rentalEnd;
        return
      }
      if(lastRent.predictedEnd){
        this.available = lastRent.predictedEnd;
        return
      }
    }

    this.available = new Date().roundNextFive();
  }

  public getNextRentId(): string | null {
    const nextRent: Material["todayRents"][0] | undefined = this.todayRents.find((rent: Material["todayRents"][0]): boolean => rent.realEnd == null);
    return nextRent ? nextRent.rentId : null;
  }

  public increaseUsageBy(milliseconds: number | null): void{
    /* update today usage & total usage in milliseconds */
    if(milliseconds != null){
      this.usage.today += milliseconds;
      this.usage.total += milliseconds;
    }
  }

  get getAvailable(): Date {
    /* get next availability for that material */
    return this.available;
  }

  public setAvailable(available: Date): void {
    /* set material available => this is the next availability for that material */
    this.available = available;
  }

  public incrementAvailableBy(milliseconds: number | null): void{
    /* increase material.available, milliseconds can be negative*/
    if(milliseconds != null){
      this.available = new Date(this.available.getTime() + milliseconds);
    } else {
     console.warn('Material.incrementAvailableBy: called with a null value');
    }
  }

  get isEnable(): boolean {
    /* return true if material is enabled else false */
    return this.enable;
  }

  static newMaterial(id: string, types: string[]): Material {
    let now: Date = new Date();
    let round: Date = new Date(new Date().setMinutes(0, 0, 0));
    return new Material(
      id,
      types,
      'ready',
      round,
      round,
      true,
      true,
      now
    )
  }

  public inUseBy(): string | null {
    let rents = this.todayRents.filter(rent => rent.rentalStart != null && rent.realEnd == null);
    switch(rents.length){
      case 0:
        return null;
      case 1:
        return rents[0].rentId;
      default:
        console.warn('Material.inUseBy() => the material is in use in multiples rents');
        return null;
    }
  }

  public addType(acronym: string): void {
    this.types.push(acronym);
  }

  public deleteType(acronym: string): boolean {
    /* return true if type was present and the material need to be updated */
    if(this.types.includes(acronym)){
      let index: number = this.types.indexOf(acronym);
      this.types = this.types.slice(index, 1);
      return true;
    } else {
      return false;
    }
  }
}
