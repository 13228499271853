export class Search {
  constructor(
    public types: string[] = [],
    public fromTime: Date = new Date().roundNextFive(),
    public padding: number = 0,
  ) {}

  static fromFirestore(data: any): Search {
    return new Search(
      data.types,
      data.fromTime.toDate(),
      data.padding
    )
  }

  public toFirestore(): any {
    return {
      types: this.types,
      fromTime: this.fromTime,
      padding: this.padding
    }
  }

  public copy(): Search {
    return  new Search([...this.types], new Date(this.fromTime.getTime()), this.padding);
  }

  get getTitle(): string | null {
    if(this.types.length == 0){return null}
    let titleArray: string[] = []
    let temp: string[] = [];
    this.types.forEach((acronym: string): void => {
      if(!temp.includes(acronym)){
        temp.push(acronym);
      }
    });

    temp.forEach((acronym: string): void => {
      let length: number = this.types.filter((searched: string): boolean => searched == acronym).length
      titleArray.push(length.toString()+' '+ acronym);
    });

    let title: string = titleArray.join(' / ');

    let nextStart: Date = new Date().roundNextFive();
    if(this.fromTime.getTime() > nextStart.getTime()){
      title += ' à partir de '+this.fromTime.HHmm();
    }
    return title;
  }

  get isDelayed(): boolean {
    let nextStart: Date = new Date().roundNextFive();
    return this.fromTime.getTime() > nextStart.getTime()
  }

  get getFromTime(): Date {
    return this.fromTime;
  }
}
