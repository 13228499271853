import {Rent} from "./rent.model";

export class TodayRents {
  /* rents Rent[] of today rents */
  constructor(
    public rents: Rent[] = []
  ) {}

  public add(rent: Rent): void {
    this.rents.push(rent);
  }

  public update(rent: Rent): void {
    let index: number = this.rents.findIndex((tested: Rent): boolean => {
      return rent.id == tested.id;
    });
    if(index != -1){
      this.rents[index] = rent;
    } else {
      this.add(rent);
    }
  }

  public remove(rent: Rent): void {
    let index: number = this.rents.findIndex((tested: Rent): boolean => {
      return rent.id == tested.id;
    });
    if(index != -1){
      this.rents.splice(index, 1);
    }
  }

  public getRentById(id: string): Rent | undefined {
    let response: Rent[] = this.rents.filter((rent: Rent): boolean => {return id == rent.id})

    if(response.length == 1){
      return response[0]
    } else if (response.length > 1){
      console.warn('getRentById: critical error more than one rent have the same id');
      return undefined;
    } else { //(response.length == 0)
      console.warn('getRentById: Id is NOT in TodayRent');
      return undefined
    }
  }

  public filterOnStatus(statusArray: Array<'pre-registered' | 'waiting' | 'called' |  'boarding' | 'sailing' | 'landing' | 'ended' | 'late'>): Rent[] {
     return this.rents.filter((rent: Rent): boolean => {
       return statusArray.includes(rent.getStatus)});
  }

  get getAssignBeepers(): string[]{
    let assignBeepers: string[] = [];
    this.filterOnStatus(['waiting', 'called']).forEach((rent: Rent): void => {
      let beeperNum: string | null = rent.beeper;
      if(beeperNum != null){
        assignBeepers.push(beeperNum);
      }
    });
    return assignBeepers;
  }

  public sortBy(property: string, asc: boolean = true): TodayRents {
    let reverse: 1 | -1 = asc ? 1: -1;
    this.rents.sort(this.sortOn(property, reverse));
    return this
  }

  private sortOn(property: any, reverse: 1 | -1){
    return (a: any, b: any): number => {
      if (a[property] < b[property]) {
        return -1 * reverse;
      } else if (a[property] > b[property]) {
        return 1 * reverse;
      } else {
        return 0;
      }
    };
  }
}
