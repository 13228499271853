import {Search} from "./search.model";
export class SearchResult {
  constructor(
    public search: Search = new Search(),
    public materialPlanned: string[] = [],
    public predictedStart: Date | null = null,
    public adults: number = 0,
    public children: number = 0,
    public prices: Map<number, number> = new Map()
  ) {}

  static fromFirestore(data: any): SearchResult {
    let price: Map<number, number> = new Map([]);
    let keys: string[] = Object.keys(data.prices);
    keys.forEach((key: string): void => {
      price.set(Number(key), data.prices[key]);
    })

    let searchResult: SearchResult = new SearchResult(
      Search.fromFirestore(data.search),
      data.materialPlanned,
      data.predictedStart? data.predictedStart.toDate(): null,
      data.adults,
      data.children,
      price
    );
    return  searchResult
  }

  public toFirestore(): any {
    let pricesToFirestore: {[duration: string]: number} = Object.fromEntries(this.prices);
    return {
      search: this.search.toFirestore(),
      materialPlanned: this.materialPlanned,
      predictedStart: this.predictedStart,
      adults: this.adults,
      children: this.children,
      prices: pricesToFirestore
    }
  }

  public copy(): SearchResult{
    const copy: SearchResult = new SearchResult();
    Object.assign(copy, this);
    copy.search = copy.search.copy();
    return copy;
  }

  get getTitle(): string | null{
    return this.search.getTitle;
  }
  get getMaterialPlanned(): string[] {
    /* Array of planned material id by the search engine */
    return this.materialPlanned;
  }

  public getPrices(): Map<number, number> {
    return this.prices;
  }

  public getPrice(duration: number | null): number | null {
    if(duration != null){
      let price: number | undefined = this.prices.get(duration);
      return price ? price: null;
    }
    return null
  }

  get getPredictedStart(): Date | null {
    return this.predictedStart;
  }

  get getAdults(): number {
    return this.adults;
  }

  get getChildren(): number {
    return this.children;
  }
}
