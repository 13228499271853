export class Customer {
  constructor(
    public lastName: string | null = null,
    public firstName: string | null = null,
    public phone: string | null= null,
    public adults: number = 0,
    public children: number =  0,
    public mindSet: 'zen' | 'reactive' | null = null,
  ){}

  static fromFirestore(data: any): Customer {
    return new Customer(
      data.lastName,
      data.firstName,
      data.phone,
      data.adults,
      data.children,
      data.mindSet
    )
  }

  public toFirestore(): any {
    return {
      lastName: this.lastName,
      firstName: this.firstName,
      phone: this.phone,
      adults: this.adults,
      children: this.children,
      mindSet: this.mindSet,
    }
  }

  public copy(): Customer {
    let copy: Customer = new Customer();
    Object.assign(copy, this);
    return copy;
  }

  public setFromForm(formValue: any): void {
    this.lastName = formValue.lastName;
    this.firstName = formValue.firstName;
    this.phone = formValue.phone;
    this.adults = formValue.adults;
    this.children = formValue.children;
    this.mindSet = formValue.mindSet;
  }

  get getCustomerName(): string {
    const lastName: string = this.lastName != null? this.lastName : '';
    const firstName: string = this.firstName != null? this.firstName: '';

    return firstName +' '+ lastName;
  }

  public getPhone(): string | null {
    return this.phone;
  }

  public getAdults(): number | null {
    return this.adults
  }

  public getChildren(): number | null {
    return this.children;
  }

  public getMindSet(): string | null {
    return this.mindSet;
  }
}
