export class Overbooking {
  constructor(
    public duration: number = 0,
    public value: number = 0,
    public details: {
      id: string,
      type: string,
      isOverbooked: boolean,
      hasAlt: boolean,
      altId: string | null,
      availableAsAlt: null | Date,
      duration: number,
      value: number
    }[] = []
  ) {}

  static fromFirestore(data: any): Overbooking {
    if(!data){
      return new Overbooking();
    }

    const details = data.details;
    for(const detail of details){
      detail.availableAsAlt = detail.availableAsAlt? detail.availableAsAlt.toDate(): null;
    }

    return new Overbooking(
      data.duration,
      data.value,
      details
    )
  }

  public toFirestore(): any{
    return {
      duration: this.duration,
      value: this.value,
      details: this.details,
    }
  }

  public copy(): Overbooking {
    let details: Overbooking['details'] = [];
    this.details.forEach((item: Overbooking['details'][0]) => {
      details.push(Object.assign({}, item));
    })

    return new Overbooking(
      this.duration,
      this.value,
      details
    )
  }
}
