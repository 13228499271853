import { Customer } from "./customer.models";
import {SearchResult} from "./searchResult.model";
import {Overbooking} from "./overbooking.model";
import {Effective} from "./effective.models";

export class Rent {
  constructor(
    public id: string | null = null,
    public status: 'pre-registered' | 'waiting' | 'called' |  'boarding' | 'sailing' | 'landing' | 'late' | 'ended' = 'pre-registered',

    public customer: Customer = new Customer(),
    public searchResult: SearchResult = new SearchResult(),
    public payment: Payment = new Payment(),
    public overbooking: Overbooking = new Overbooking(),
    public effective: Effective = new Effective(),
    public materialPlanned: string[] = [],
    public materialAssigned: string[] = [],
    public materialReturned: string[] = [],
    public duration: number | null = null,
    public beeper: string | null = null,
    public comment: string | null = null,
    public preRegistrationMethod: 'self' | 'preRegistrationStation' | 'reception' | null = null,

    /* time */
    public preRegistrationTime: Date | null = null,
    public registrationTime: Date | null = null,
    public predictedStart: Date | null = null,
    public callTime: Date | null = null,
    public boardingTime: Date | null = null,
    public boardingDuration: number | null = null,
    public rentalStart: Date | null = null,
    public rentalStartDelta: number | null = null,
    public rentalEnd: Date | null = null,
    public realEnd: Date | null = null,
    public checkoutTime: Date | null = null,
    public realDuration: number | null = null,
    public rentalEndDelta: number | null = null, // en ms positive if ahead of rentalEnd Do not round to avoid border effect
  ){}

  static fromFirestore(id: string, data: any): Rent {
    let rent: Rent = new Rent();
    rent.id = id;
    rent.status = data.status;
    rent.customer = Customer.fromFirestore(data.customer);
    rent.searchResult = SearchResult.fromFirestore(data.searchResult);
    rent.payment = Payment.fromFirestore(data.payment);
    rent.overbooking = Overbooking.fromFirestore(data.overbooking);
    rent.effective = Effective.fromFirestore(data.effective);
    rent.materialPlanned = data.materialPlanned;
    rent.materialAssigned = data.materialAssigned;
    rent.materialReturned = data.materialReturned;
    rent.duration = data.duration;
    rent.beeper = data.beeper;
    rent.comment = data.comment;
    rent.preRegistrationMethod = data.preRegistrationMethod;
    rent.preRegistrationTime = data.preRegistrationTime? data.preRegistrationTime.toDate(): null;
    rent.registrationTime = data.registrationTime? data.registrationTime.toDate(): null;
    rent.predictedStart = data.predictedStart? data.predictedStart.toDate(): null;
    rent.callTime = data.callTime? data.callTime.toDate(): null;
    rent.boardingTime = data.boardingTime? data.boardingTime.toDate(): null;
    rent.boardingDuration = data.boardingDuration;
    rent.rentalStart = data.rentalStart? data.rentalStart.toDate(): null;
    rent.rentalStartDelta = data.rentalStartDelta;
    rent.rentalEnd = data.rentalEnd? data.rentalEnd.toDate(): null;
    rent.realEnd = data.realEnd? data.realEnd.toDate(): null;
    rent.checkoutTime = data.checkoutTime? data.checkoutTime.toDate(): null;
    rent.realDuration = data.realDuration;
    rent.rentalEndDelta = data.rentalEndDelta;


    return rent;
  }

  public toFirestore(): any {
    let classFree: any = {
      id: this.id,
      status: this.status,
      customer: this.customer.toFirestore(),
      searchResult: this.searchResult.toFirestore(),
      payment: this.payment.toFirestore(),
      overbooking: this.overbooking.toFirestore(),
      effective: this.effective.toFirestore(),
      materialPlanned: this.materialPlanned,
      materialAssigned: this.materialAssigned,
      materialReturned: this.materialReturned,
      duration: this.duration,
      beeper: this.beeper,
      comment: this.comment,
      preRegistrationMethod: this.preRegistrationMethod,
    }

    this.preRegistrationTime ? classFree.preRegistrationTime = this.preRegistrationTime: null;
    this.registrationTime ? classFree.registrationTime = this.registrationTime: null;
    this.predictedStart ? classFree.predictedStart = this.predictedStart: null;
    this.callTime ? classFree.callTime = this.callTime: null;
    this.boardingTime? classFree.boardingTime = this.boardingTime: null;
    this.boardingDuration? classFree.boardingDuration = this.boardingDuration: null;
    this.rentalStart ? classFree.rentalStart = this.rentalStart: null;
    this.rentalStartDelta ? classFree.rentalStartDelta = this.rentalStartDelta : null
    this.rentalEnd ? classFree.rentalEnd = this.rentalEnd: null;
    this.realEnd ? classFree.realEnd = this.realEnd: null;
    this.checkoutTime ? classFree.checkoutTime = this.checkoutTime: null;
    this.rentalEndDelta ? classFree.rentalEndDelta = this.rentalEndDelta: null;

    return classFree;
  }

  public copy(): Rent {
    /* return a deep copy of rent */
    const copy: Rent = new Rent();
    Object.assign(copy, this);
    copy.customer = this.customer.copy();
    copy.overbooking = this.overbooking.copy();
    copy.effective = this.effective.copy();
    copy.searchResult = this.searchResult.copy();
    copy.payment = this.payment.copy();
    return copy;
  }

  get getId(): string | null {
    return this.id;
  }

  get searchTitle(): string | null {
    /* get searchTitle from searchResult.search */
    return this.searchResult.search.getTitle;
  }

  get getMaterialPlanned(): string[]{
    /* get array of planned material ids */
    return this.materialPlanned;
  }

  public setMaterialPlanned(materialIds: string[]): void {
    /* set material planned for the rent */
    this.materialPlanned = materialIds;
  }

  get getMaterialAssigned(): string[]{
    /* get array of in use material  by the customer */
    return this.materialAssigned
  }

  public setMaterialAssigned(materialsIds: string[]): void {
    /* set material assigned for the rent */
    this.materialAssigned = materialsIds;
  }

  public materialPlannedToAssigned(idToPass: string): void {
    /* Pass provided material id from planned to assigned */
    const errorCheck: number = this.materialPlanned.length;
    this.materialPlanned = this.materialPlanned.filter((id: string): boolean => {return id != idToPass});
    if(this.materialPlanned.length == errorCheck){
      console.warn('error id to pass in: materialPlannedToAssign undefined')
      return;
    }
    this.materialAssigned.push(idToPass);
  }

  public materialAssignedToReturned(idToPass: string): void {
    /* Pass provided material id from assigned to returned */
    /* update effectiveDuration */
    const errorCheck: number = this.materialAssigned.length;
    this.materialAssigned = this.materialAssigned.filter((id: string): boolean => {return id != idToPass});
    if(this.materialAssigned.length == errorCheck){
      console.warn('error id to pass in: materialAssignedToReturned undefined');
      return;
    }
    this.effective.update(idToPass, this.rentalStart);
    this.materialReturned.push(idToPass);
  }

  get getMaterialReturned(): string[]{
    /* get array of returned material id */
    return this.materialReturned;
  }

  get getMaterials(): string[]{
    /* concat of materialPlanned and materialAssigned sort by id*/
    const materialsArray: string[] = this.getMaterialPlanned.concat(this.getMaterialAssigned);
    materialsArray.sort();
    return materialsArray
  }

  get getCustomerName(): string{
    /* Get Customer: 'firstName lastName' */
    return this.customer.getCustomerName;
  }

  get getLastName(): string | null {
    /* get LastName from customer or null */
    return this.customer.lastName;
  }

  get getFirstName(): string | null {
    /* get FirstName from customer */
    return this.customer.firstName;
  }

  get getPhone(): string | null {
    /* get Phone from customer */
    return this.customer.phone;
  }

  get getAdults(): number {
    /* get Adults form search result */
    return this.customer.adults
  }

  get getChildren(): number {
    /* get Children from search result */
    return this.customer.children
  }

  get getGroupComposition(): string {
    let adults = this.getAdults;
    let children = this.getChildren;
    let composition = '';
    if(adults && adults > 0){
      composition += adults.toString()+ ' ';
      composition += adults == 1 ? 'adulte': 'adultes';
    }

    if(adults > 0 && children > 0){
      composition += ', ';
    }

    if(children && children > 0){
      composition += children.toString()+ ' ';
      composition += children == 1 ? 'enfant': 'enfants';
    }

    return composition;
  }

  get getMindSet(): string | null {
    /* get mindSet from customer */
    return this.customer.mindSet;
  }

  get getDuration(): number | null {
    return this.duration;
  }

  get getPreRegistrationTime(): Date | null {
    return this.preRegistrationTime
  }
  get getPredictedStart(): Date | null {
    return this.predictedStart
  }

  get getPredictedEnd(): Date | null {
    if(this.predictedStart instanceof Date && typeof this.getDuration === 'number'){
      return new Date(this.predictedStart.getTime() + (this.getDuration * 60 * 1000))
    }
    return null;
  }

  get getRentalStart(): Date | null {
    return this.rentalStart
  }

  get getRentalEnd(): Date | null {
    return this.rentalEnd;
  }

  get getRealEnd(): Date | null {
    return this.realEnd;
  }

  get getTimeLeft(): number | undefined {
    /* return time left in minute; or undefined */
    if(this.rentalEnd && this.duration){
      let timeLeft: number = this.rentalEnd.getTime() - new Date().getTime();
      return Math.round(timeLeft / (60* 1000));
    }
    return;
  }

  get getStatus(): 'pre-registered' | 'waiting' | 'called' |  'boarding' | 'sailing' | 'landing' | 'late' | 'ended' {
    return this.status;
  }

  get getBeeper(): string | null {
    return this.beeper;
  }

  get getPaymentMethod(): 'card' | 'cash' | 'mixed' | null {
    return this.payment.paymentMethod;
  }
  get getCard(): number | null {
    /* get card from payment */
    return this.payment.card;
  }

  get getCash(): number | null {
    /* get cash from payment */
    return this.payment.cash;
  }

  get getPrice(): number | null {
    /* get price from payment */
    return this.payment.price;
  }

  get getComment(): string | null {
    return this.comment;
  }

  get getSearchResult(): SearchResult {
    return this.searchResult;
  }

  public getDelta(start:  'now'| 'preRegistrationTime' | 'registrationTime' | 'boardingTime' | 'predictedStart' | 'callTime' | 'rentalStart' | 'rentalEnd' | 'realEnd',
                  end:  'now'| 'preRegistrationTime' | 'registrationTime' | 'boardingTime' | 'predictedStart' | 'callTime' | 'rentalStart' | 'rentalEnd' | 'realEnd',
                  absolute: boolean = false,
                  unit: 'minutes' | 'milliseconds' = 'minutes'
                  ): null | number {
    /* return delta in miliseconds beetweeen to property if both are set else null (end - start = result) */
    let _start: Date | null
    _start = start != 'now'? this[start]: new Date();

    let _end: Date | null
    _end = end != 'now'? this[end]: new Date();

    if(_start == null || _end == null){
      return null;
    }
    let delta: number = _end.getTime() - _start.getTime()
    if(unit == 'minutes'){
      delta = Math.round(delta / (60* 1000));
    }
    if(absolute){
      delta = Math.abs(delta);
    }

    return delta;
  }

  public setId(id: string): void {
    this.id = id;
  }

  public setPredictedStart(date: Date | null): void {
    /* set predictedStart */
    this.predictedStart = date;
  }

  public setPreRegistrationMethod(method: 'self' | 'preRegistrationStation' | 'reception' | null): void{
    if(this.preRegistrationMethod == null || method == null){
      this.preRegistrationMethod = method;
    }
  }

  public setLastName(lastName: string | undefined): void {
    lastName && lastName != '' ? this.customer.lastName = lastName: this.customer.lastName = null;
  }

  public setFirstName(firstName: string | undefined): void {
    firstName && firstName != '' ? this.customer.firstName = firstName: this.customer.firstName = null;
  }

  public setPhone(phone: string | undefined): void {
    this.customer.phone = phone && phone != '' ? phone: null;
  }

  public setMindSet(mindSet: 'zen' | 'reactive' | null): void {
    this.customer.mindSet = mindSet;
  }

  public setAdults(adults: number): void {
    this.customer.adults = adults;
  }

  public setChildren(children: number): void {
    this.customer.children = children;
  }

  public setRentalStart(date: Date | null): void{
    /* set rental start only*/
    this.rentalStart = date;
  }

  public setRentalEnd(date: Date | null): void{
    /* set rental end only */
    this.rentalEnd = date;
  }

  public setBeeper(beeperId: string | null): void {
    this.beeper = beeperId;
  }
  public setStatus(status: 'pre-registered' | 'waiting' | 'called' |  'boarding' | 'sailing' | 'landing' | 'ended' | 'late'): void {
    /* Set rent status and update different times
    * pre-registered: customer pre-registered waiting for definite registration
    * waiting: customer has been placed in waiting state
    * called: waiting customer has been call to present himself at boarding
    * boarding: the customer has been take in charge of equiping and material delivery
    * sailing: customer is in current use of the rented materials
    * landing: customer has returned all rented materials in wait for check out
    * ended: customer has been checked out, rent is terminated */
    switch (status){
      case 'pre-registered':
        this.status = 'pre-registered';
        this.preRegistrationTime = new Date();
        break;
      case 'waiting':
        this.status = 'waiting';
        this.registrationTime = this.registrationTime? this.registrationTime: new Date();
        break;
      case 'called':
        this.status = 'called';
        this.callTime = new Date();
        break;
      case 'boarding':
        this.status = 'boarding';
        this.registrationTime = this.registrationTime? this.registrationTime: new Date();
        this.boardingTime = this.boardingTime? this.boardingTime: new Date();
        break;
      case 'sailing':
        this.status = 'sailing';
        this.boardingDuration = this.getDelta('boardingTime', 'now', false, "milliseconds");
        this.rentalStartDelta = this.getDelta('predictedStart', 'rentalStart', false, "milliseconds");
        this.rentalStart = new Date();
        if(typeof this.duration === 'number'){
          let rentalEnd: Date = new Date(this.rentalStart.getTime() + this.duration * 60 * 1000);
          this.rentalEnd = rentalEnd.roundNextFive();
        } else {
          console.warn('error in rent.model: RENT CAN\'T START WITHOUT A DURATION');
        }
        break;
      case 'landing':
        this.status = 'landing';
        this.realEnd = new Date();
        this.rentalEndDelta = this.getDelta('rentalEnd', 'realEnd', false, "milliseconds");
        break;
      case 'ended':
        this.status = 'ended';
        break;
      case 'late':
        this.status = 'late';
        break;
    }
  }

  public setDuration(duration: number): void {
    /* set duration, card, casf, price, paymentMethod */
    this.duration = duration;
    this.setPrice();
  }

  private setPrice(): void {
    /* private method: Set and update price correctly */
    switch(this.getPaymentMethod){
      case 'card':
        this.setCard(this.searchResult.getPrice(this.duration));
        break;
      case 'cash':
        this.setCash(this.searchResult.getPrice(this.duration));
        break;
      case 'mixed':
        let cash: number | null = this.getCash;
        let card: number | null = this.getCard;
        let price: number | null = this.getPrice;
        if(card && cash && price){
          if(price >= cash){
            this.setCard(price - cash);
          } else {
            this.setCard(null);
            this.setCash(price);
          }
        }
        break;
      case null:
        this.searchResult.getPrice(this.duration)
        this.setCard(this.searchResult.getPrice(this.duration));
        break;
      default:
        console.warn('error payment method need to be card cash mixed or null');
    }
  }

  public setComment(comment: string | null){
    this.comment = comment;
  }

  public setCard(card: number | null){
    /* Set card, price, paymentMethod */
    this.payment.setCard(card);
  }

  public setCash(cash: number  | null): void {
    /* Set cash, price, paymentMethod */
    this.payment.setCash(cash);
  }

  get getPaymentComposition(): string {
    switch(this.getPaymentMethod){
      case 'card':
        return this.getCard + '€ en carte';
        break;
      case 'cash':
        return this.getCash + '€ en espèces';
        break;
      case 'mixed':
        return this.getPrice + '€ (carte: '+ this.getCard +'€, espèces: '+ this.getCash + '€)'
      default:
        return 'gratuit';
    }
  }
}

export class Payment {
  constructor(
    public price: number | null = null,
    public card: number | null = null,
    public cash: number | null = null,
    public paymentMethod: 'card' | 'cash' | 'mixed' | null = 'card'
  ) {}

  static fromFirestore(data: any): Payment {
    return new Payment(
      data.price,
      data.card,
      data.cash,
      data.paymentMethod
    )
  }

  public toFirestore(): any{
    return {
      price: this.price,
      card: this.card,
      cash: this.cash,
      paymentMethod: this.paymentMethod
    }
  }

  public copy(): Payment {
    let copy: Payment = new Payment();
    Object.assign(copy, this);
    return copy
  }

  private setPaymentMethod(): void {
    /* private method set paymentMethod based on card and cash values */
    if (this.card && !this.cash) {
      this.paymentMethod = 'card';
    }
    if (!this.card && this.cash) {
      this.paymentMethod = 'cash';
    }
    if (this.card && this.cash) {
      this.paymentMethod = 'mixed';
    }
    if(!this.card && !this.cash){
      this.paymentMethod = null
    }
  }

  public setCard(card: number | null): void {
    /* set card, price and paymentMethod */
    this.card = card;
    let _card: number = this.card ? this.card : 0;
    let _cash: number = this.cash ? this.cash: 0;
    this.price = _card + _cash > 0 ? _card + _cash: null;
    this.setPaymentMethod();
  }

  public setCash(cash: number | null): void {
    /* set cash, price and paymentMethod */
    this.cash = cash;
    let _card: number = this.card ? this.card : 0;
    let _cash: number = this.cash ? this.cash: 0;
    this.price = _card + _cash > 0 ? _card + _cash: null;
    this.setPaymentMethod();
  }

  public switchPaymentMethod(): void {
    /* reverse cash and card, set paymentMethod */
    const card: number | null = this.card;
    const cash: number | null = this.cash
    this.card = cash;
    this.cash = card;
    this.setPaymentMethod();
  }
}
