import {Rent} from "./rent.model";

export type HourString =
  | '00:00'
  | '01:00'
  | '02:00'
  | '03:00'
  | '04:00'
  | '05:00'
  | '06:00'
  | '07:00'
  | '08:00'
  | '09:00'
  | '10:00'
  | '11:00'
  | '12:00'
  | '13:00'
  | '14:00'
  | '15:00'
  | '16:00'
  | '17:00'
  | '18:00'
  | '19:00'
  | '20:00'
  | '21:00'
  | '22:00'
  | '23:00'
  | '24:00';

export type ByHourObject = { [hour in HourString]: Statistics };

type WeekdayString =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

type ByWeekdayObject = {
  [weekday in WeekdayString]: {
    total: Statistics;
    byHour: ByHourObject;
  };
};

export class Statistics {
  constructor(
    public dayWorked: number = 1,
    public revenues: Revenues = new Revenues(),
    public rents: RentsData = new RentsData(),
    public customers: CustomersData = new CustomersData(),
    public steps: Steps = new Steps(),
    public stepsDuration: StepsDuration = new StepsDuration(),
    public fleetUsage: FleetUsage = new FleetUsage(),
  ) {}

  static fromFirestore(d: any): Statistics{
    let s: Statistics = new Statistics()
    d.dayWorked ? s.dayWorked = d.dayWorked: null;
    d.revenues ? s.revenues = Revenues.fromFirestore(d.revenues): null;
    d.rents ? s.rents = RentsData.fromFirestore(d.rents): null;
    d.customers ? s.customers = CustomersData.fromFirestore(d.customers): null;
    d.steps ? s.steps = Steps.fromFirestore(d.steps): null;
    d.stepsDuration ? s.stepsDuration = StepsDuration.fromFirestore(d.stepsDuration): null;
    d.fleetUsage ? s.fleetUsage = FleetUsage.fromFirestore(d.fleetUsage): null;

    return s;
  }
  public toFirestore(): any {
    return {
      dayWorked: this.dayWorked,
      revenues: this.revenues.toFirestore(),
      rents: this.rents.toFirestore(),
      customers: this.customers.toFirestore(),
      steps: this.steps.toFirestore(),
      stepsDuration: this.stepsDuration.toFirestore(),
      fleetUsage: this.fleetUsage.toFirestore(),
    };
  }
}

class Revenues {
  constructor(
    public total: number = 0,
    public overbooking: number = 0,
    public card: number = 0,
    public cash: number = 0
  ) {}

  static fromFirestore(d: any): Revenues{
    let revenues: Revenues = new Revenues();
    d.total ? revenues.total = d.total: null;
    d.overbooking? revenues.overbooking = d.overbooking: null;
    d.card ? revenues.card = d.card: null;
    d.cash ? revenues.cash = d.cash: null;
    return revenues;
  }
  public toFirestore(): any {
    return {
      total: this.total,
      overbooking: this.overbooking,
      card: this.card,
      cash: this.cash,
    };
  }
}

class RentsData {
  public durations: {
    sold: number;
    effective: number;
    overbooking: number;
  }
  public timing: {
    atStart: DeltaCount,
    atEnd: DeltaCount,
  }
  constructor() {
    this.durations = { sold: 0, effective: 0, overbooking: 0};
    this.timing = {
      atStart: new DeltaCount(),
      atEnd: new DeltaCount(),
    };
  }

  static fromFirestore(d: any): RentsData{
    let rentsData: RentsData = new RentsData();
    d.durations && d.durations.sold ? rentsData.durations.sold = d.durations.sold: null;
    d.durations && d.durations.effective ? rentsData.durations.effective = d.durations.effective: null;
    d.durations && d.durations.overbooking ? rentsData.durations.overbooking = d.durations.overbooking: null;
    d.timing && d.timing.atStart ? rentsData.timing.atStart = DeltaCount.fromFirestore(d.timing.atStart): null;
    d.timing && d.timing.atEnd ? rentsData.timing.atEnd = DeltaCount.fromFirestore(d.timing.atEnd): null;

    return rentsData;
  }

  public toFirestore(): any {
    return {
      durations: {
        sold: this.durations.sold,
        effective: this.durations.effective,
        overbooking: this.durations.overbooking,
      },
      timing: {
        atStart: this.timing.atStart.toFirestore(),
        atEnd: this.timing.atEnd.toFirestore(),
      },
    };
  }
}

export class DeltaCount {
  constructor(
    public advance: Delta = new Delta(),
    public delay: Delta = new Delta()
  ) {}

  static fromFirestore(d: any): DeltaCount{
    let deltaCount: DeltaCount = new DeltaCount();
    d.advance ? deltaCount.advance = Delta.fromFirestore(d.advance): null;
    d.delay ? deltaCount.delay = Delta.fromFirestore(d.delay): null;
    return deltaCount;
  }

  public toFirestore() {
    return {
      advance: this.advance.toFirestore(),
      delay: this.delay.toFirestore(),
    };
  }
}

class Delta {
  constructor(public nb: number = 0, public ms: number = 0) {}

  static fromFirestore(d: any){
    let delta: Delta = new Delta();
    d.nb ? delta.nb = d.nb: null;
    d.ms ? delta.ms = d.ms: null;
    return delta;
  }
  public toFirestore() {
    return {
      nb: this.nb,
      ms: this.ms,
    };
  }
}

class CustomersData {
  constructor(
    public boarding: {
      adults: number,
      children: number,
    } =  {adults: 0, children: 0},
    public landing: {
      adults: number,
      children: number
    } = {adults: 0, children: 0}
  ) {}

  static fromFirestore(d: any): CustomersData{
    let customerData: CustomersData = new CustomersData()
    d.boarding && d.boarding.adults ? customerData.boarding.adults = d.boarding.adults: null;
    d.boarding && d.boarding.children ? customerData.boarding.children = d.boarding.children: null;
    d.landing && d.landing.adults ? customerData.landing.adults = d.landing.adults: null;
    d.landing && d.landing.children ? customerData.landing.children = d.landing.children: null;

    return customerData
  }
  public toFirestore() {
    return {
      boarding: this.boarding,
      landing: this.landing,
    };
  }
}

class Steps {
  constructor(
    public preRegisteredSelf: number = 0,
    public preRegisteredStation: number = 0,
    public preRegisteredReception: number = 0,
    public registered: number = 0,
    public called: number = 0,
    public boarded: number = 0,
    public landed: number = 0,
    public checkedOut: number = 0
  ) {}

  static fromFirestore(d: any): Steps{
    let steps: Steps = new Steps();
    d.preRegisteredSelf? steps.preRegisteredSelf = d.preRegisteredSelf: null;
    d.preRegisteredStation? steps.preRegisteredStation = d.preRegisteredStation: null;
    d.preRegisteredReception? steps.preRegisteredReception = d.preRegisteredReception: null;
    d.registered? steps.registered = d.registered: null;
    d.called? steps.called = d.called: null;
    d.boarded? steps.boarded = d.boarded: null;
    d.landed? steps.landed = d. landed: null;
    d.checkedOut? steps.checkedOut = d.checkedOut: null;
    return steps;
  }
  public toFirestore(){
    return {
      preRegisteredSelf: this.preRegisteredSelf,
      preRegisteredStation: this.preRegisteredStation,
      preRegisteredReception: this.preRegisteredReception,
      registered: this.registered,
      boarded: this.boarded,
      landed: this.landed,
      checkedOut: this.checkedOut
    }
  }
}
class StepsDuration {
  constructor(
    public waitingInLineDuration: Delta = new Delta(),
    public callDuration: Delta = new Delta(),
    public waitingDuration: Delta = new Delta(),
    public boardingDuration: Delta = new Delta(),
    public sailingDuration: Delta = new Delta(),
    public checkoutDuration: Delta = new Delta()
  ) {}

  static fromFirestore(d: any): StepsDuration{
    let stepsDuration: StepsDuration = new StepsDuration()
    d.waitingInLineDuration? stepsDuration.waitingInLineDuration = Delta.fromFirestore(d.waitingInLineDuration): null;
    d.callDuration? stepsDuration.callDuration = Delta.fromFirestore(d.callDuration): null;
    d.waitingDuration? stepsDuration.waitingDuration = Delta.fromFirestore(d.waitingDuration): null;
    d.boardingDuration? stepsDuration.boardingDuration = Delta.fromFirestore(d.boardingDuration): null;
    d.sailingDuration? stepsDuration.sailingDuration = Delta.fromFirestore(d.sailingDuration): null;
    d.checkoutDuration? stepsDuration.checkoutDuration = Delta.fromFirestore(d.checkoutDuration): null;
    return stepsDuration;
  }

  public toFirestore() {
    return {
      waitingInLineDuration: this.waitingInLineDuration.toFirestore(),
      callDuration: this.callDuration.toFirestore(),
      waitingDuration: this.waitingDuration.toFirestore(),
      boardingDuration: this.boardingDuration.toFirestore(),
      sailingDuration: this.sailingDuration.toFirestore(),
      checkoutDuration: this.checkoutDuration.toFirestore()
    };
  }
}

type FleetUsageObject = {[type: string]: FleetUsageDetails}

class FleetUsage {
  byType: FleetUsageObject
  constructor() {
    this.byType = {} as FleetUsageObject
  }

  static fromFirestore(data: any): FleetUsage{
    const fleetUsage: FleetUsage = new FleetUsage();
    // Check if the 'byType' field exists in the Firestore data
    if (data.byType) {
      // Iterate through the 'byType' keys
      for (const type in data.byType) {
        if (data.byType.hasOwnProperty(type)) {
          // Create an instance of FleetUsageDetails and assign values from Firestore
          const details: FleetUsageDetails = new FleetUsageDetails(
            data.byType[type].sailing || 0,
            data.byType[type].booked || 0,
            data.byType[type].overbooked || 0
          );

          // Assign the FleetUsageDetails instance to the corresponding type
          fleetUsage.byType[type] = details;
        }
      }
    }

    return fleetUsage;
  }

  public toFirestore(): {[p: string]:  FleetUsageDetails["toFirestore"]} {
    const byTypeFirestore: any = {}
    for (const type in this.byType) {
        byTypeFirestore[type] = this.byType[type].toFirestore();
    }
    return {
      byType: byTypeFirestore
    };
  }
}

class FleetUsageDetails {
  constructor(
    public sailing: number = 0,
    public booked: number = 0,
    public overbooked: number = 0
  ){}

  public toFirestore() {
    return {
      sailing: this.sailing,
      booked: this.booked,
      overbooked: this.overbooked,
    };
  }
}

export class StatisticsDocument {
  total: Statistics;
  byHour: ByHourObject;
  byWeekday: {
    [weekday in WeekdayString]: {
      total: Statistics;
      byHour: ByHourObject;
    };
  };
  note: string | null

  constructor() {
    this.total = new Statistics();
    this.byHour = {} as ByHourObject;
    this.byWeekday = {} as ByWeekdayObject;
    this.note = null
  }

  static fromFirestore(data: any): StatisticsDocument{
    const statisticsDocument: StatisticsDocument = new StatisticsDocument();

    // Parse the 'total' field using the 'fromFirestore' method of the Statistics class
    data.total? statisticsDocument.total = Statistics.fromFirestore(data.total): statisticsDocument.total.dayWorked = 0;

    // Parse the 'byHour' field if it exists
    if (data.byHour) {
      for (const hour of Object.keys(data.byHour) as HourString[]) {
        statisticsDocument.byHour[hour] = Statistics.fromFirestore(data.byHour[hour]);
      }
    }

    // Parse the 'byWeekday' field if it exists
    if (data.byWeekday) {
      for (const weekday of Object.keys(data.byWeekday) as WeekdayString[]) {
        const weekdayStats = data.byWeekday[weekday];
        statisticsDocument.byWeekday[weekday] = {
          total: Statistics.fromFirestore(weekdayStats.total),
          byHour: {} as ByHourObject, // Initialize the nested object
        };

        // Parse the 'byHour' field within 'byWeekday'
        if (weekdayStats.byHour) {
          for (const hour of Object.keys(weekdayStats.byHour) as HourString[]) {
            statisticsDocument.byWeekday[weekday].byHour[hour] = Statistics.fromFirestore(
              weekdayStats.byHour[hour]
            );
          }
        }
      }
    }

    // Parse the 'note' field
    statisticsDocument.note = data.note || null;

    return statisticsDocument;
  }

  public toFirestore() {
    const byHourFirestore: any = {};

    for (const hour of Object.keys(this.byHour) as HourString[]) {
      byHourFirestore[hour] = this.byHour[hour].toFirestore();
    }

    const byWeekdayFirestore: any = {};

    for (const weekday of Object.keys(this.byWeekday) as WeekdayString[]) {
      const weekdayStats = this.byWeekday[weekday];
      byWeekdayFirestore[weekday] = {
        total: weekdayStats.total.toFirestore(),
        byHour: {} as ByHourObject, // Initialize the nested object
      };

      for (const hour of Object.keys(weekdayStats.byHour) as HourString[]) {
        byWeekdayFirestore[weekday].byHour[hour] = weekdayStats.byHour[hour].toFirestore();
      }
    }

    return {
      total: this.total.toFirestore(),
      byHour: byHourFirestore,
      byWeekday: byWeekdayFirestore,
      note: this.note,
    };
  }

  public addRent(rent: Rent): void {
    this.update(rent);
  }

  public removeRent(rent: Rent): void {
    this.update(rent, true);
  }

  private getHour(date: Date | null): HourString {
    if(date == null){
      throw new Error('Error in statisticsDocument.update: dates is null can\'t get hour => aboard adding');
    }
    /** timezone may become variable if we internationalize the app => take it from shop settings */
    const timeZone = 'Europe/Paris'; // French timezone

    const options = {
      timeZone: timeZone,
      hour: '2-digit' as const,
      hour12: false, // Use 24-hour format
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedTime: string = formatter.format(date)+':00';
    return formattedTime as HourString;
  }

  private getWeekday(date: Date | null): WeekdayString{
    if(date == null){
      throw new Error('Error in statisticsDocument.update: dates is null can\'t get weekday => aboard adding')
    }
    // Convert the input date to the specified time zone
    const timeZone = 'Europe/Paris'; // French timezone
    const formatter = new Intl.DateTimeFormat('en-US', { weekday: 'long', timeZone});
    const weekday = formatter.format(date);

    return weekday.toLowerCase() as WeekdayString;
  }

  private checkUndefined(hour: HourString, weekday: WeekdayString): void {
    if (!this.byHour[hour]) {
      this.byHour[hour] = new Statistics(); // Or create it as needed
    }

    if(!this.byWeekday[weekday]){
      this.byWeekday[weekday] = {
        total: new Statistics(),
        byHour: {} as  ByHourObject
      }
    }

    if(!this.byWeekday[weekday].byHour[hour]){
      this.byWeekday[weekday].byHour[hour] = new Statistics();
    }
  }

  private update(rent: Rent, remove: boolean = false){
    try{
      const reverse: 1 | -1 = remove? -1: 1;
      /** rent nb / workedDay **/

      /** revenues **/
      const price: number | null = rent.getPrice;
      if(price){
        let value: number = price * reverse;
        let hour: HourString = this.getHour(rent.registrationTime);
        let weekday: WeekdayString = this.getWeekday(rent.registrationTime);
        this.checkUndefined(hour, weekday);
        this.total.revenues.total += value;
        this.byHour[hour].revenues.total += value;
        this.byWeekday[weekday].total.revenues.total += value;
        this.byWeekday[weekday].byHour[hour].revenues.total += value;
      }

      const overbooking: number = rent.overbooking.value
      if(overbooking){
        let value: number = overbooking * reverse;
        let hour: HourString = this.getHour(rent.registrationTime);
        let weekday: WeekdayString = this.getWeekday(rent.registrationTime);
        this.checkUndefined(hour, weekday);
        this.total.revenues.overbooking += value;
        this.byHour[hour].revenues.overbooking += value;
        this.byWeekday[weekday].total.revenues.overbooking += value;
        this.byWeekday[weekday].byHour[hour].revenues.overbooking += value;
      }

      const card: number | null = rent.getCard;
      if(card){
        let value: number = card * reverse;
        let hour: HourString = this.getHour(rent.registrationTime);
        let weekday: WeekdayString = this.getWeekday(rent.registrationTime);
        this.checkUndefined(hour, weekday);
        this.total.revenues.card += value;
        this.byHour[hour].revenues.card += value;
        this.byWeekday[weekday].total.revenues.card += value;
        this.byWeekday[weekday].byHour[hour].revenues.card += value;
      }

      const cash: number | null = rent.getCash;
      if(cash){
        let value: number = cash * reverse;
        let hour: HourString = this.getHour(rent.registrationTime);
        let weekday: WeekdayString = this.getWeekday(rent.registrationTime);
        this.checkUndefined(hour, weekday);
        this.total.revenues.cash += value;
        this.byHour[hour].revenues.cash += value;
        this.byWeekday[weekday].total.revenues.cash += value;
        this.byWeekday[weekday].byHour[hour].revenues.cash += value;
      }

      /** rents **/
      /** rental start delta **/
      if(rent.predictedStart && rent.rentalStart){
        // the rent as already started
        rent.rentalStartDelta = rent.getDelta('predictedStart', 'rentalStart', false, 'milliseconds');
        if(rent.rentalStartDelta){
          let value: number = rent.rentalStartDelta * reverse;
          let hour: HourString = this.getHour(rent.rentalStart); // the delta at start will be store in the rentalStart period
          let weekday: WeekdayString = this.getWeekday(rent.rentalStart);
          this.checkUndefined(hour, weekday);
          if(rent.rentalStartDelta < 0){
            this.total.rents.timing.atStart.advance.nb += 1;
            this.total.rents.timing.atStart.advance.ms += value;
            this.byHour[hour].rents.timing.atStart.advance.nb += 1;
            this.byHour[hour].rents.timing.atStart.advance.ms += value;
            this.byWeekday[weekday].total.rents.timing.atStart.advance.nb += 1;
            this.byWeekday[weekday].total.rents.timing.atStart.advance.ms += value;
            this.byWeekday[weekday].byHour[hour].rents.timing.atStart.advance.nb += 1;
            this.byWeekday[weekday].byHour[hour].rents.timing.atStart.advance.ms += value;
          }

          if(rent.rentalStartDelta > 60000) { // more than one minute late
            this.total.rents.timing.atStart.delay.nb += 1;
            this.total.rents.timing.atStart.delay.ms += value;
            this.byHour[hour].rents.timing.atStart.delay.nb += 1;
            this.byHour[hour].rents.timing.atStart.delay.ms += value;
            this.byWeekday[weekday].total.rents.timing.atStart.delay.nb += 1;
            this.byWeekday[weekday].total.rents.timing.atStart.delay.ms += value;
            this.byWeekday[weekday].byHour[hour].rents.timing.atStart.delay.nb += 1;
            this.byWeekday[weekday].byHour[hour].rents.timing.atStart.delay.ms += value;
          }
        }
      }

      /**rental end delta **/
      if(rent.rentalEnd && rent.realEnd){
        rent.rentalEndDelta = rent.getDelta("rentalEnd", "realEnd", false, 'milliseconds');
        if(rent.rentalEndDelta){
          let value: number = rent.rentalEndDelta * reverse;
          let hour: HourString = this.getHour(rent.realEnd); // the delta at return will be store in the realEnd period
          let weekday: WeekdayString = this.getWeekday(rent.realEnd);
          this.checkUndefined(hour, weekday);

          if(rent.rentalEndDelta < 0){ // advance
            this.total.rents.timing.atEnd.advance.nb += 1;
            this.total.rents.timing.atEnd.advance.ms += value;
            this.byHour[hour].rents.timing.atEnd.advance.nb += 1;
            this.byHour[hour].rents.timing.atEnd.advance.ms += value;
            this.byWeekday[weekday].total.rents.timing.atEnd.advance.nb += 1;
            this.byWeekday[weekday].total.rents.timing.atEnd.advance.ms += value;
            this.byWeekday[weekday].byHour[hour].rents.timing.atEnd.advance.nb += 1;
            this.byWeekday[weekday].byHour[hour].rents.timing.atEnd.advance.ms += value;
          }

          if(rent.rentalEndDelta > 60000){ // late by more than a minute
            this.total.rents.timing.atEnd.delay.nb += 1;
            this.total.rents.timing.atEnd.delay.ms += value;
            this.byHour[hour].rents.timing.atEnd.delay.nb += 1;
            this.byHour[hour].rents.timing.atEnd.delay.ms += value;
            this.byWeekday[weekday].total.rents.timing.atEnd.delay.nb += 1;
            this.byWeekday[weekday].total.rents.timing.atEnd.delay.ms += value;
            this.byWeekday[weekday].byHour[hour].rents.timing.atEnd.delay.nb += 1;
            this.byWeekday[weekday].byHour[hour].rents.timing.atEnd.delay.ms += value;
          }
        }
      }

      /** Rent Duration: sold, effective and overbooking **/
      /** sold **/
      if(rent.duration){
        let materialCount: number = 0;
        rent.materialReturned ? materialCount += rent.materialReturned.length: null;
        rent.materialAssigned ? materialCount += rent.materialAssigned.length: null;
        rent.materialPlanned ? materialCount += rent.materialAssigned.length: null;

        let value: number = rent.duration * 60000 * materialCount * reverse;
        let hour: HourString = this.getHour(rent.registrationTime); // the delta at start will be store in the rentalStart period
        let weekday: WeekdayString = this.getWeekday(rent.registrationTime);
        this.checkUndefined(hour, weekday);


        this.total.rents.durations.sold += value;
        this.byHour[hour].rents.durations.sold += value;
        this.byWeekday[weekday].total.rents.durations.sold += value;
        this.byWeekday[weekday].byHour[hour].rents.durations.sold += value;
      }

      /** effective **/
      if(rent.effective.duration){
        let value: number = rent.effective.duration * reverse;
        let hour: HourString = this.getHour(rent.rentalStart); // the delta at start will be store in the rentalStart period
        let weekday: WeekdayString = this.getWeekday(rent.rentalStart);
        this.checkUndefined(hour, weekday);

        this.total.rents.durations.effective += value;
        this.byHour[hour].rents.durations.effective += value;
        this.byWeekday[weekday].total.rents.durations.effective += value;
        this.byWeekday[weekday].byHour[hour].rents.durations.effective += value;
      }

      /** overbooking **/
      if(rent.overbooking.duration){
        let value: number = rent.overbooking.duration * reverse;
        let hour: HourString = this.getHour(rent.rentalStart); // the delta at start will be store in the rentalStart period
        let weekday: WeekdayString = this.getWeekday(rent.rentalStart);
        this.checkUndefined(hour, weekday);

        this.total.rents.durations.overbooking += value;
        this.byHour[hour].rents.durations.overbooking += value;
        this.byWeekday[weekday].total.rents.durations.overbooking += value;
        this.byWeekday[weekday].byHour[hour].rents.durations.overbooking += value;
      }

      /** Steps **/
      if(rent.preRegistrationTime){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.preRegistrationTime);
        let weekday: WeekdayString = this.getWeekday(rent.preRegistrationTime);
        this.checkUndefined(hour, weekday);

        if(rent.preRegistrationMethod == 'self'){
          this.total.steps.preRegisteredSelf += value;
          this.byHour[hour].steps.preRegisteredSelf += value;
          this.byWeekday[weekday].total.steps.preRegisteredSelf += value;
          this.byWeekday[weekday].byHour[hour].steps.preRegisteredSelf += value;
        }

        if(rent.preRegistrationMethod == 'preRegistrationStation'){
          this.total.steps.preRegisteredStation += value;
          this.byHour[hour].steps.preRegisteredStation += value;
          this.byWeekday[weekday].total.steps.preRegisteredStation += value;
          this.byWeekday[weekday].byHour[hour].steps.preRegisteredStation += value;
        }

        if(rent.preRegistrationMethod == 'reception'){
          this.total.steps.preRegisteredReception += value;
          this.byHour[hour].steps.preRegisteredReception += value;
          this.byWeekday[weekday].total.steps.preRegisteredReception += value;
          this.byWeekday[weekday].byHour[hour].steps.preRegisteredReception += value;
        }
      }

      if(rent.registrationTime){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.registrationTime);
        let weekday: WeekdayString = this.getWeekday(rent.registrationTime);
        this.checkUndefined(hour, weekday);

        this.total.steps.registered += value;
        this.byHour[hour].steps.registered += value;
        this.byWeekday[weekday].total.steps.registered += value;
        this.byWeekday[weekday].byHour[hour].steps.registered += value;
      }

      if(rent.callTime){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.callTime);
        let weekday: WeekdayString = this.getWeekday(rent.callTime);
        this.checkUndefined(hour, weekday);

        this.total.steps.called += value;
        this.byHour[hour].steps.called += value;
        this.byWeekday[weekday].total.steps.called += value;
        this.byWeekday[weekday].byHour[hour].steps.called += value;
      }


      if(rent.rentalStart){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.rentalStart);
        let weekday: WeekdayString = this.getWeekday(rent.rentalStart);
        this.checkUndefined(hour, weekday);

        this.total.steps.boarded += value;
        this.byHour[hour].steps.boarded += value;
        this.byWeekday[weekday].total.steps.boarded += value;
        this.byWeekday[weekday].byHour[hour].steps.boarded += value;
      }

      if(rent.realEnd){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.realEnd); // the delta at start will be store in the rentalStart period
        let weekday: WeekdayString = this.getWeekday(rent.realEnd);
        this.checkUndefined(hour, weekday);

        this.total.steps.landed += value;
        this.byHour[hour].steps.landed += value;
        this.byWeekday[weekday].total.steps.landed += value;
        this.byWeekday[weekday].byHour[hour].steps.landed += value;
      }

      if(rent.checkoutTime){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.checkoutTime); // the delta at start will be store in the rentalStart period
        let weekday: WeekdayString = this.getWeekday(rent.checkoutTime);
        this.checkUndefined(hour, weekday);

        this.total.steps.checkedOut += value;
        this.byHour[hour].steps.checkedOut += value;
        this.byWeekday[weekday].total.steps.checkedOut += value;
        this.byWeekday[weekday].byHour[hour].steps.checkedOut += value;
      }

       /** customers **/

       if(rent.rentalStart){
         let hour: HourString = this.getHour(rent.rentalStart);
         let weekday: WeekdayString = this.getWeekday(rent.rentalStart);
         this.checkUndefined(hour, weekday);

         if(rent.customer.adults){
           let value: number = rent.customer.adults * reverse;
           this.total.customers.boarding.adults += value;
           this.byHour[hour].customers.boarding.adults += value;
           this.byWeekday[weekday].total.customers.boarding.adults += value;
           this.byWeekday[weekday].byHour[hour].customers.boarding.adults += value;
         }

         if(rent.customer.children){
           let value: number = rent.customer.children * reverse;
           this.total.customers.boarding.children += value;
           this.byHour[hour].customers.boarding.children += value;
           this.byWeekday[weekday].total.customers.boarding.children += value;
           this.byWeekday[weekday].byHour[hour].customers.boarding.children += value;
         }
       }

       if(rent.realEnd){
         let hour: HourString = this.getHour(rent.realEnd);
         let weekday: WeekdayString = this.getWeekday(rent.realEnd);
         this.checkUndefined(hour, weekday);

         if(rent.customer.adults){
           let value: number = rent.customer.adults * reverse;
           this.total.customers.landing.adults += value;
           this.byHour[hour].customers.landing.adults += value;
           this.byWeekday[weekday].total.customers.landing.adults += value;
           this.byWeekday[weekday].byHour[hour].customers.landing.adults += value;
         }

         if(rent.customer.children){
           let value: number = rent.customer.children * reverse;
           this.total.customers.landing.children += value;
           this.byHour[hour].customers.landing.children += value;
           this.byWeekday[weekday].total.customers.landing.children += value;
           this.byWeekday[weekday].byHour[hour].customers.landing.children += value;
         }
       }

      /** RENT STEP DURATION **/
      // Calculate waiting time in line only for 'self' preRegistrationMethod
      if (rent.preRegistrationMethod === 'self') {
        if(rent.preRegistrationTime && rent.registrationTime){
          let value: number = 1 * reverse;
          let hour: HourString = this.getHour(rent.registrationTime);
          let weekday: WeekdayString = this.getWeekday(rent.registrationTime);
          this.checkUndefined(hour, weekday);

          this.total.stepsDuration.waitingInLineDuration.nb += value;
          this.byHour[hour].stepsDuration.waitingInLineDuration.nb += value;
          this.byWeekday[weekday].total.stepsDuration.waitingInLineDuration.nb += value;
          this.byWeekday[weekday].byHour[hour].stepsDuration.waitingInLineDuration.nb += value;

          const waitInLineMs: number = (rent.registrationTime.getTime() - rent.preRegistrationTime.getTime()) *  reverse;
          this.total.stepsDuration.waitingInLineDuration.ms += waitInLineMs;
          this.byHour[hour].stepsDuration.waitingInLineDuration.ms += waitInLineMs;
          this.byWeekday[weekday].total.stepsDuration.waitingInLineDuration.ms += waitInLineMs;
          this.byWeekday[weekday].byHour[hour].stepsDuration.waitingInLineDuration.ms += waitInLineMs;
        }
      }

      /** For rent on waiting list calculate waiting between registration and call **/
      if(rent.registrationTime && rent.callTime){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.registrationTime);
        let weekday: WeekdayString = this.getWeekday(rent.registrationTime);
        this.checkUndefined(hour, weekday);

        this.total.stepsDuration.waitingDuration.nb += value;
        this.byHour[hour].stepsDuration.waitingDuration.nb += value;
        this.byWeekday[weekday].total.stepsDuration.waitingDuration.nb += value;
        this.byWeekday[weekday].byHour[hour].stepsDuration.waitingDuration.nb += value;

        const waitingMs: number = (rent.callTime.getTime() - rent.registrationTime.getTime()) *  reverse;
        this.total.stepsDuration.waitingDuration.ms += waitingMs;
        this.byHour[hour].stepsDuration.waitingDuration.ms += waitingMs;
        this.byWeekday[weekday].total.stepsDuration.waitingDuration.ms += waitingMs;
        this.byWeekday[weekday].byHour[hour].stepsDuration.waitingDuration.ms += waitingMs;
      }

      /** For rent on waiting list calculate time taken by the customer to present themselves at the boarding gate **/
      if(rent.callTime && rent.boardingTime){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.callTime);
        let weekday: WeekdayString = this.getWeekday(rent.callTime);
        this.checkUndefined(hour, weekday);

        this.total.stepsDuration.callDuration.nb += value;
        this.byHour[hour].stepsDuration.callDuration.nb += value;
        this.byWeekday[weekday].total.stepsDuration.callDuration.nb += value;
        this.byWeekday[weekday].byHour[hour].stepsDuration.callDuration.nb += value;

        const callDurationMs: number = (rent.boardingTime.getTime() - rent.callTime.getTime()) *  reverse;
        this.total.stepsDuration.callDuration.ms += callDurationMs;
        this.byHour[hour].stepsDuration.callDuration.ms += callDurationMs;
        this.byWeekday[weekday].total.stepsDuration.callDuration.ms += callDurationMs;
        this.byWeekday[weekday].byHour[hour].stepsDuration.callDuration.ms += callDurationMs;
      }

      /** for all rents calculate boarding duration **/
      if(rent.boardingTime && rent.rentalStart){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.boardingTime);
        let weekday: WeekdayString = this.getWeekday(rent.boardingTime);
        this.checkUndefined(hour, weekday);

        this.total.stepsDuration.boardingDuration.nb += value;
        this.byHour[hour].stepsDuration.boardingDuration.nb += value;
        this.byWeekday[weekday].total.stepsDuration.boardingDuration.nb += value;
        this.byWeekday[weekday].byHour[hour].stepsDuration.boardingDuration.nb += value;

        const boardingDurationMs: number = (rent.rentalStart.getTime() - rent.boardingTime.getTime()) *  reverse;
        this.total.stepsDuration.boardingDuration.ms += boardingDurationMs;
        this.byHour[hour].stepsDuration.boardingDuration.ms += boardingDurationMs;
        this.byWeekday[weekday].total.stepsDuration.boardingDuration.ms += boardingDurationMs;
        this.byWeekday[weekday].byHour[hour].stepsDuration.boardingDuration.ms += boardingDurationMs;
      }

      /** for all rents calculate real rent duration **/
      if(rent.rentalStart && rent.realEnd){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.rentalStart);
        let weekday: WeekdayString = this.getWeekday(rent.rentalStart);
        this.checkUndefined(hour, weekday);

        this.total.stepsDuration.sailingDuration.nb += value;
        this.byHour[hour].stepsDuration.sailingDuration.nb += value;
        this.byWeekday[weekday].total.stepsDuration.sailingDuration.nb += value;
        this.byWeekday[weekday].byHour[hour].stepsDuration.sailingDuration.nb += value;

        const sailingDurationMs: number = (rent.realEnd.getTime() - rent.rentalStart.getTime()) *  reverse;
        this.total.stepsDuration.sailingDuration.ms += sailingDurationMs;
        this.byHour[hour].stepsDuration.sailingDuration.ms += sailingDurationMs;
        this.byWeekday[weekday].total.stepsDuration.sailingDuration.ms += sailingDurationMs;
        this.byWeekday[weekday].byHour[hour].stepsDuration.sailingDuration.ms += sailingDurationMs;
      }

      /** for all rent calculate checkout Duration **/
      if(rent.realEnd && rent.checkoutTime){
        let value: number = 1 * reverse;
        let hour: HourString = this.getHour(rent.realEnd);
        let weekday: WeekdayString = this.getWeekday(rent.realEnd);
        this.checkUndefined(hour, weekday);

        this.total.stepsDuration.checkoutDuration.nb += value;
        this.byHour[hour].stepsDuration.checkoutDuration.nb += value;
        this.byWeekday[weekday].total.stepsDuration.checkoutDuration.nb += value;
        this.byWeekday[weekday].byHour[hour].stepsDuration.checkoutDuration.nb += value;

        const checkoutDurationMs: number = (rent.checkoutTime.getTime() - rent.realEnd.getTime()) *  reverse;
        this.total.stepsDuration.checkoutDuration.ms += checkoutDurationMs;
        this.byHour[hour].stepsDuration.checkoutDuration.ms += checkoutDurationMs;
        this.byWeekday[weekday].total.stepsDuration.checkoutDuration.ms += checkoutDurationMs;
        this.byWeekday[weekday].byHour[hour].stepsDuration.checkoutDuration.ms += checkoutDurationMs;
      }

      /** Fleet Usage **/
      if(rent.searchResult && rent.searchResult.search && rent.searchResult.search.types){
        let sailingFrom: Date | null = null;
        let sailingTo: Date | null = null;

        /** is scheduled but isn't started yet: its purpose is only for in day overview **/
        if(!rent.rentalStart && rent.predictedStart && rent.getPredictedEnd){
          sailingFrom = rent.predictedStart;
          sailingTo = rent.getPredictedEnd;
        }

        /** is on going for day overview AND can be the normal use case for daily statistics IF rent are on more than one day **/
        if(rent.rentalStart && !rent.realEnd && rent.getPredictedEnd){
          sailingFrom = rent.rentalStart;
          sailingTo = rent.getPredictedEnd;
        }

        /** is terminated, normal use case for daily statistics **/
        if(rent.rentalStart && rent.realEnd){
          sailingFrom = rent.rentalStart;
          sailingTo = rent.realEnd;
        }

        const sailingWeekdayAndHour: {weekday: WeekdayString, hour: HourString}[] =[];
        if(sailingFrom && sailingTo){
          let sailingFromMs: number = sailingFrom.getTime();
          let sailingToMs: number = sailingTo.getTime()
          for(let i: number = sailingFromMs; i < sailingToMs; i += 3600000){
            sailingWeekdayAndHour.push({
              weekday: this.getWeekday(new Date(i)),
              hour: this.getHour(new Date(i))
            });
          }
        }

        rent.searchResult.search.types.forEach((type: string) => {
          !this.total.fleetUsage.byType[type] ? this.total.fleetUsage.byType[type] = new FleetUsageDetails(): null;
          this.total.fleetUsage.byType[type].sailing += 1 * reverse;

          sailingWeekdayAndHour.forEach((weekdayAndHour: {weekday: WeekdayString, hour: HourString}) => {
            this.checkUndefined(weekdayAndHour.hour, weekdayAndHour.weekday);
            if(rent.rentalStart && weekdayAndHour.weekday == this.getWeekday(rent.rentalStart)){
              !this.byHour[weekdayAndHour.hour].fleetUsage.byType[type]? this.byHour[weekdayAndHour.hour].fleetUsage.byType[type] = new FleetUsageDetails(): null;
              this.byHour[weekdayAndHour.hour].fleetUsage.byType[type].sailing += 1 * reverse;
            }

            !this.byWeekday[weekdayAndHour.weekday].total.fleetUsage.byType[type]? this.byWeekday[weekdayAndHour.weekday].total.fleetUsage.byType[type] = new FleetUsageDetails(): null;
            this.byWeekday[weekdayAndHour.weekday].total.fleetUsage.byType[type].sailing += 1 *reverse;

            !this.byWeekday[weekdayAndHour.weekday].byHour[weekdayAndHour.hour].fleetUsage.byType[type]? this.byWeekday[weekdayAndHour.weekday].byHour[weekdayAndHour.hour].fleetUsage.byType[type] = new FleetUsageDetails(): null;
            this.byWeekday[weekdayAndHour.weekday].byHour[weekdayAndHour.hour].fleetUsage.byType[type].sailing += 1 * reverse;
          });
        });
      }

    } catch(error: any){
      console.warn(error);
      throw new Error(error)
    }
  }
}
