import {Material} from "./material.model";

export class Fleet {
  constructor(
    public materials: Material[] = [],
    public filtered: Material[] = []
  ){}

  /* Method on fleet */
  public add(material: Material): void {
    /* add material to materials array of fleet */
    this.materials.push(material);
  }

  public update(material: Material): void {
    /* update material in materials array of fleet */
    let index: number = this.materials.findIndex((tested: Material): boolean => {
      return material.id == tested.id;
    });
    if(index != -1){
      this.materials[index] = material;
    } else {
      this.add(material);
    }
  }

  public remove(material: Material): void {
    /* delete material of materials array of fleet */
    let index: number = this.materials.findIndex((tested: Material): boolean => {
      return material.id == tested.id;
    });
    if(index != -1){
      this.materials.splice(index, 1);
    }
  }

  public getMaterialById(id: string): Material | null {
    let response: Material[] = this.materials.filter((material: Material): boolean => {
      return material.id == id
    });

    if(response.length == 0){
      return null
    }

    if(response.length == 1){
      return response[0];
    }

    console.warn('fleet internal error: more than one matérial have the same id: '+id);
    window.alert('fleet internal error: more than one matérial have the same id: '+ id);
    return null
  }

  public copy(): Fleet {
    /* True copy, can be modified with harm elsewhere */
    const fleet: Fleet = new Fleet();
    this.materials.forEach((material: Material): void => {
      fleet.add(material.copy());
    });
    return fleet;
  }

  public updateAvailable(openingTime: Date): this {
    /* this method update available of all material if:
    * available is before opening time available become opening time */
    for(const material of this.materials){
      let now: Date = new Date();
      if(material.getAvailable < openingTime && now < openingTime){
        material.setAvailable(openingTime);
      }
    }
    return  this
  }

  public sortByAvailable(direction?: 'asc' | 'desc'): this {
    /* sort materials default asc, optional desc*/
    const reverse = direction == 'desc'? -1: 1;
    this.materials.sort((materialA: Material, materialB: Material): number => {
      /* sort material on available */
      if(materialA.getAvailable < materialB.getAvailable){
        return -1 * reverse
      }

      if(materialA.getAvailable > materialB.getAvailable){
        return 1 * reverse
      }

      /* if same availability */
      return 0;
    });
    return this
  }

  public sortByStatus(orderedStatus: string[]): this {
    let statusPriority: Map<string, number> = new Map<string, number>([])
    let maxPriority: number = orderedStatus.length;
    orderedStatus.forEach((status: string, index: number) => {
      statusPriority.set(status, maxPriority - index);
    });

    this.materials.sort((materialA: Material, materialB: Material): number => {
      let aStatusPriority: number |undefined = statusPriority.get(materialA.status)
      let bStatusPriority: number |undefined = statusPriority.get(materialB.status)

      /* error check */
      if(!aStatusPriority || !bStatusPriority){
        console.warn('error in sort by status');
        if(!aStatusPriority){
          console.log(materialA.status + ' is not in: [' +orderedStatus.join(' ,')+ ' ]');
          console.log(materialA);
        }
        if(!bStatusPriority){
          console.log(materialB.status + ' is not in: [' +orderedStatus.join(' ,')+ ' ]');
          console.log(materialB);
        }
        return 0;
      }


      if(aStatusPriority > bStatusPriority){
        return -1
      }

      if(aStatusPriority < bStatusPriority){
        return 1
      }
      return 0;
    });

    return this;
  }

  public sortOnStored(): this {
    /* sort on stored: stored: false then stored: true */
    this.materials.sort((materialA: Material, materialB: Material): number => {
      if(!materialA.stored && materialB.stored){
        return -1;
      }

      if(materialA.stored && !materialB.stored){
        return 1;
      }

      return 0;
    });
    return this;

  }

  public resetFilter(): this {
    this.filtered = this.materials.slice();
    return this;
  }

  public filterEnabled(): Fleet {
    /* return a new Fleet with enabled = true materials */
    return new Fleet(this.materials.filter((material: Material) => material.enable));
  }

  public filterDisabled(): Fleet {
    /* return a new Fleet with enabled = false materials */
    return new Fleet(this.materials.filter((material: Material) => !material.enable));
  }

  public filterAvailableNow(): Fleet {
    /* return a new Fleet with material.available <= now */
    const now: Date = new Date();
    return new Fleet(this.materials.filter((material: Material): boolean => material.getAvailable <= now.roundNextFive()));
  }

  public filterAvailableLater(): Fleet{
    /* return a new Fleet with material.available > now */
    const now: Date = new Date();
    return new Fleet(this.materials.filter((material: Material): boolean => material.getAvailable > now.roundNextFive()));
  }

  public filterByStatus(status: Material["status"]): this {
    /* return new Fleet with material.status == Params */
    this.filtered = this.filtered.filter((material: Material): boolean => material.getStatus == status);
    return this;
  }

  public filterByTypes(types: string[]): this {
    this.filtered = this.filtered.filter((material: Material): boolean => material.getTypes.some((type: string): boolean => types.includes(type)))
    return this;
  }

  public filterRemoveIds(ids: string[]): this {
    this.filtered = this.filtered.filter((material: Material): boolean => !ids.includes(material.getId));
    return this;
  }
}
