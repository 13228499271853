export class Effective {
  constructor(
    public duration: number = 0,
    public details: {
      id: string,
      duration: number
    }[] = []
  ) {}

  static fromFirestore(data: any): Effective {
    let effective: Effective = new Effective();

    if(data && data.duration && data.details){
      effective.duration = data.duration;
      effective.details = data.details;
    }

    return effective;
  }

  public toFirestore(): any{
    return {
      duration: this.duration,
      details: this.details
    }
  }

  public copy(): Effective {
    let details: Effective['details'] = [];
    this.details.forEach(detail => {
      details.push(Object.assign({}, detail));
    });

    return new Effective(
      this.duration,
      details
    )
  }

  public update(idToPass: string, rentalStart: Date | null): void {
    if(rentalStart == null){
      console.warn('error in effective duration update, rentalStart is null');
      return;
    }

    const duration: number = new Date().getTime() - rentalStart.getTime();
    this.duration += duration;
    this.details.push(
      {id: idToPass, duration: duration}
    )
    console.log(this);
  }
}
